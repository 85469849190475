import React, { Component, Fragment, Children } from 'react';
import * as PropTypes from 'prop-types';
// import * as queryString from 'query-string';
// import { URLSearchParams } from 'react-router-dom';
import store from 'store';
// import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { initApplication } from '../application/application-actions';
import { LoadingPineapple } from 'common/components';
import { CSRF_ACCESS_TOKEN, CSRF_REFRESH_TOKEN } from '../authentication/auth.api';
import NotificationsSystem, { atalhoTheme, dismissNotification } from 'reapop';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

class AppDataProvider extends Component {
  state = {
    progressMessage: '',
    isReady: false,
    error: null,
  };

  componentWillMount() {
    this.setState({ isReady: false }, async () => {
      const values = new URLSearchParams().getAll(this.props.router.location.search);
      // console.log('values',values)
      if (values[CSRF_ACCESS_TOKEN] && values[CSRF_REFRESH_TOKEN]) {
        store.set(CSRF_REFRESH_TOKEN, values[CSRF_REFRESH_TOKEN]);
        store.set(CSRF_ACCESS_TOKEN, values[CSRF_ACCESS_TOKEN]);
      }
      const { initApplicationAction } = this.props;
      // console.log('this.props',this.props)
      await initApplicationAction();
      this.setState({ isReady: true });
    });
  }

  render() {
    const { children: application } = this.props;
    const { isReady, progressMessage } = this.state;
    // console.log('this.props AppDataProvidor',this.props)
    return (
      <>
        <NotificationsSystem
          notifications={this.props.notifications}
          dismissNotification={(id) => this.props.dismissNotificationAction(id)}
          theme={atalhoTheme}
        />
        {isReady ? (
          Children.only(application)
        ) : (
          <LoadingPineapple shouldSpin={true} loadingText={progressMessage} />
        )}
      </>
    );
  }
}

AppDataProvider.propTypes = {
  children: PropTypes.element.isRequired,
  initApplicationAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

const mapDispatchToProps = {
  initApplicationAction: initApplication,
  dismissNotificationAction: dismissNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppDataProvider));
