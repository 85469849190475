import { useState, useEffect, createRef } from 'react';
import { Chart } from 'chart.js';
import RegularCard from 'common/components/RegularCard/RegularCard';
import RegularCardHeader from 'common/components/RegularCardHeader/RegularCardHeader';
import InfoBubble from 'common/components/InfoBubble.jsx/InfoBubble';
import { getChartConfig } from './chartConfig';
import { useWindowSize } from 'common/utils/customHooks';
import { Box } from '@mui/material';

const ComplianceBucketsChart = ({ complianceBuckets }) => {
  const chartRef = createRef();
  const { height } = useWindowSize();
  const chartHeight = height - 240;
  // eslint-disable-next-line no-unused-vars
  const [chart, setChart] = useState(null);
  const chartInfo = () => {
    return <span>{"Today's snapshot of rolling 30 day patient compliance."}</span>;
  };

  useEffect(() => {
    const chartData = Object.values(complianceBuckets);
    const myChartRef = chartRef.current.getContext('2d');
    const config = getChartConfig({ chartData });
    const newChart = new Chart(myChartRef, config);
    setChart(newChart);
    return () => newChart.destroy();
  }, []);

  return (
    <>
      <RegularCard
        key={'COMPLIANCE_CHART'}
        cardTitle={
          <RegularCardHeader
            titleNode={'30-day Compliance'}
            infoNode={<InfoBubble infoMessage={chartInfo()} />}
          />
        }
        content={
          <Box sx={{ position: 'relative', height: `${chartHeight}px`, width: '100%' }}>
            <canvas id={`compliance-histogram-chart}`} ref={chartRef}></canvas>
          </Box>
        }
      />
    </>
  );
};

export default ComplianceBucketsChart;
