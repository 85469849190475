import React from 'react';
import { InfoField } from 'common/components';
// import { IconButton, CircularProgress, Tooltip } from '@mui/material';
// import RefreshIcon from '@mui/icons-material/Refresh';
import { formatDateTime } from 'common/utils/_moment';
import { useClasses } from 'common/utils/utils';

const RefreshList = ({ lastUpdate, handleRefresh, isUpdating }) => {
  const classes = useClasses({
    main: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginLeft: '.75rem',
    },
  });

  return (
    <div className={classes.main}>
      <InfoField
        label='Updated'
        value={formatDateTime(lastUpdate, { format: 'YYYY/MM/DD, h:mm A', timezone: true })}
      />
      {/* {isUpdating ? (
        <CircularProgress
          color='secondary'
          size={24}
          className={classes.icon}
          data-testid='refresh-status-spinner'
        />
      ) : (
        <Tooltip title={'Refresh'}>
          <IconButton
            disableRipple={true}
            size='small'
            className={classes.icon}
            onClick={handleRefresh}
            data-testid='refresh-status-button'
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </div>
  );
};

export default RefreshList;
