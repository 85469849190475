import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setTaskView,
  setPatientTaskSteps,
  getPatientTasksRequest,
  setPatientTaskFilterLabel,
} from '../store/actions';
import patientSelectors from '../store/selectors';
import { getTaskSteps } from '../store/api';
import { PAGE_SIZE } from 'features/patient/store/sagas';
import patientSelector from 'features/patient/store/selectors';
import TaskForm from 'features/patient/TasksPane/TaskForm/TaskForm';
import TaskList from 'features/patient/TasksPane/TaskList/TaskList';
import TaskItem from 'features/patient/TasksPane/TaskItem/TaskItem';
import ActionConfig from 'features/patient/ActionsConfig.json';
import ActionPane from './ActionPane/ActionPane';
import { TASKS_VIEW } from 'common/constants/patient';
import { LoadingPineapple } from 'common/components';
import {
  Grid,
  Typography,
  IconButton,
  Box,
  TablePagination,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WestIcon from '@mui/icons-material/West';

const Item = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const TasksPane = ({ patientId, clinicId }) => {
  const dispatch = useDispatch();
  const tasksLoaded = useSelector(patientSelectors.getPatientTasksLoaded);
  const tasks = useSelector(patientSelectors.getTasks);
  const tasksFilterLabel = useSelector(patientSelectors.getPatientTasksFilterLabel);
  const taskSteps = useSelector(patientSelector.getPatientTaskSteps);
  const count = useSelector(patientSelector.getTaskCount);
  const page = useSelector(patientSelector.getTaskPage);
  const taskView = useSelector(patientSelector.getTaskView);
  const [taskSummary, setTaskSummary] = useState('');
  const [taskId, setTaskId] = useState();
  const [taskItemClicked, setTaskItemClick] = useState(false);
  const [taskDate, setTaskDate] = useState();

  const handleChange = (event) => {
    if (event.target.value === 'due') {
      dispatch(getPatientTasksRequest({ patientId, page: 0, filters: { completed: 'null' } }));
    } else if (event.target.value === 'completed') {
      dispatch(getPatientTasksRequest({ patientId, page: 0, filters: { completed__ne: 'null' } }));
    } else {
      dispatch(getPatientTasksRequest({ patientId, page: 0 }));
    }
    dispatch(setPatientTaskFilterLabel({ filterLabel: event.target.value }));
  };

  const onPageChange = (_, p) => {
    let filters = {};
    if (tasksFilterLabel === 'completed') filters = { completed__ne: 'null' };
    if (tasksFilterLabel === 'due') filters = { completed: 'null' };
    dispatch(getPatientTasksRequest({ patientId, page: p, filters }));
  };

  const onUpdateTaskItem = (taskId, summary) => {
    let taskSteps = getTaskSteps(taskId)
      .then((res) => {
        dispatch(setPatientTaskSteps({ taskSteps: res.data.data.steps }));
      })
      .catch((error) => {
        console.error(error);
        // TODO: error handling
      });
    setTaskItemClick(true);
    setTaskSummary(summary);
    setTaskId(taskId);
    dispatch(setTaskView({ taskView: TASKS_VIEW.formView }));
    return taskSteps;
  };

  const onReadOnlyTaskItem = (taskId, summary, date) => {
    let taskSteps = getTaskSteps(taskId)
      .then((res) => {
        dispatch(setPatientTaskSteps({ taskSteps: res.data.data.steps }));
      })
      .catch((error) => {
        console.error(error);
        // TODO: error handling
      });
    setTaskItemClick(true);
    setTaskSummary(summary);
    setTaskDate(date);
    setTaskId(taskId);
    dispatch(setTaskView({ taskView: TASKS_VIEW.itemView }));
    return taskSteps;
  };

  const handleCloseTaskItem = () => {
    dispatch(setTaskView({ taskView: TASKS_VIEW.listView }));
    setTaskItemClick(false);
    setTaskSummary('');
  };

  if (!tasksLoaded) {
    return <LoadingPineapple shouldSpin={true} />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container sx={{ width: '100%', padding: 0 }}>
        <Grid container xs={12}>
          <Grid item xs>
            <Item>
              {taskView === TASKS_VIEW.itemView && (
                <IconButton id='go-back-button' onClick={handleCloseTaskItem}>
                  <WestIcon />
                </IconButton>
              )}
            </Item>
          </Grid>
          <Grid item xs={8}>
            <Item>
              <Typography sx={{ marginBottom: '.25rem', fontWeight: '900' }} variant='h6'>
                {taskView === TASKS_VIEW.listView ? 'Tasks' : 'Task'}
              </Typography>
            </Item>
          </Grid>
          <Grid item xs>
            <Item>
              {taskView === TASKS_VIEW.listView && <ActionPane setTaskSummary={setTaskSummary} />}
            </Item>
          </Grid>
        </Grid>
        {taskView === TASKS_VIEW.listView && (
          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={4}
            xs={12}
          >
            <Grid item xs={3} sx={{ marginBottom: '1rem' }}>
              <Tooltip title={'Filter By Type'} placement='bottom-end'>
                <FormControl sx={{ width: '100%' }} size='small' variant='filled'>
                  <Select
                    value={tasksFilterLabel}
                    label={'Filter By Type'}
                    onChange={handleChange}
                    sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                    disableUnderline
                  >
                    <MenuItem value='all'>All</MenuItem>
                    <MenuItem value='due'>Due</MenuItem>
                    <MenuItem value='completed'>Completed</MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        {taskView === TASKS_VIEW.listView && (
          <TaskList
            list={tasks}
            dateLabel={tasksFilterLabel === 'all' ? 'date' : tasksFilterLabel}
            // onTaskClick={onTaskClick}
            onUpdateTaskItem={onUpdateTaskItem}
            onReadOnlyTaskItem={onReadOnlyTaskItem}
            count={count}
            tasksLoaded={tasksLoaded}
            onPageChange={onPageChange}
          />
        )}
        {taskView === TASKS_VIEW.listView && (
          <Grid
            xs={11}
            justifyContent='end'
            alignItems='center'
            sx={{ width: '100%', margin: '0 auto 3rem' }}
          >
            <TablePagination
              component='div'
              count={count}
              rowsPerPage={PAGE_SIZE}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              rowsPerPageOptions={[]}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={onPageChange}
            />
          </Grid>
        )}
        {taskView === TASKS_VIEW.itemView && (
          <TaskItem date={taskDate} taskSteps={taskSteps} message={taskSummary} />
        )}
        {taskView === TASKS_VIEW.formView && (
          <TaskForm
            patientId={patientId}
            clinicId={clinicId}
            taskData={taskItemClicked ? taskSteps : ActionConfig['steps']}
            newTask={taskItemClicked ? false : true}
            summary={taskSummary}
            setTaskItemClick={setTaskItemClick}
            setTaskSummary={setTaskSummary}
            setTaskId={setTaskId}
            taskId={taskItemClicked ? taskId : null}
          />
        )}
      </Grid>
    </Box>
  );
};

export default TasksPane;
