import React from 'react';
import CallIcon from './CallIcon/CallIcon';
import EmailIcon from './EmailIcon/EmailIcon';
import NoteIcon from './NoteIcon/NoteIcon';
import TextIcon from './TextIcon/TextIcon';
import WarningIcon from './WarningIcon/WarningIcon';

const getIcon = ({ type }) => {
  let icon;
  if (!type) return null;
  switch (type) {
    case 'call':
    case 'Call':
    case 'Call Log':
    case 'Call the patient':
      icon = <CallIcon size='small' />;
      break;
    case 'email':
      icon = <EmailIcon size='small' />;
      break;
    case 'text':
      icon = <TextIcon size='small' />;
      break;
    case 'note':
      icon = <NoteIcon size='small' />;
      break;
    case 'warning':
      icon = <WarningIcon size='small' />;
      break;
    default:
      icon = <CallIcon size='small'></CallIcon>;
      break;
  }
  return icon;
};

const IconByType = (type) => {
  return <>{getIcon(type)}</>;
};

export default IconByType;
