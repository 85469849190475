import React, { useState } from 'react';
import styles from './LoginView.style';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { logoSrc } from 'common/assets';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, TextField, Typography, Link } from '@mui/material';
import { SubmitButton } from 'common/components';
import { getProviderRedirect } from '../auth.api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { loginRequest } from '../store/actions';
import selectors from '../store/selectors';
import { useEffect } from 'react';
import { rootPath } from 'common/utils/paths';
import { useClasses } from 'common/utils/utils';

const schema = yup.object().shape({
  useProvider: yup.boolean(),
  email: yup.string().email('must be a valid email').required('email is required'),
  password: yup.string().when('useProvider', {
    is: true,
    then: yup.string(),
    otherwise: yup.string().required('password is required'),
  }),
  provider: yup.string(),
});

const LoginView = () => {
  const history = useNavigate();
  const classes = useClasses(styles);
  // console.log('classes',classes.container)
  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const isUpdating = useSelector(selectors.getIsUpdating);
  const loginError = useSelector(selectors.getError);
  const dispatch = useDispatch();
  const providerDomains = useSelector(selectors.getProviderDomains);
  const { register, watch, setError, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });
  // TODO: remove unused code
  // const upRes = useForm({
  //   resolver: yupResolver(schema),
  // });
  const errors = formState.errors;
  const { isSubmitting } = formState;
  const watchEmail = watch('email');
  const [provider, setProvider] = useState('');

  useEffect(() => {
    if (!!isAuthenticated) {
      history(rootPath);
    }
  }, [history, isAuthenticated]);

  useEffect(() => {
    const email_parts = !!watchEmail ? watchEmail.split('@') : [];
    if (email_parts.length > 1) {
      const domain = email_parts.pop().toLowerCase();
      for (const [provider, domains] of Object.entries(providerDomains)) {
        if (domains.includes(domain)) {
          setProvider(provider);
          return;
        }
      }
    }
    setProvider('');
  }, [watchEmail, providerDomains]);

  const handleLogin = async (email, password) => {
    dispatch(loginRequest({ email, password }));
  };

  const handleLoginWithProvider = async (email) => {
    try {
      const domain = email.split('@').pop();
      // We have to get the URL from the API instead of just making a link the user can click on
      // because of caching reasons (computer picks up on the redirect and will eventually just skip
      // redirecting to freemanhealth and just reload the dashboard)
      // TODO: this might have been fixed with the Cache-Control: no-store 302 header
      const redirectUrl = await getProviderRedirect(domain);
      global.location.assign(redirectUrl);
    } catch (e) {
      setError('form', {
        type: 'manual',
        message: e.message,
      });
    }
  };

  const onSubmit = (data) => {
    if (!!provider) {
      handleLoginWithProvider(data.email);
    } else {
      handleLogin(data.email, data.password);
    }
  };

  return (
    <div className={classes.container}>
      <Link className={classes.logoWrapper} href={'https://www.ensodata.com/'}>
        <img className={classes.logoImage} src={logoSrc} alt='logo' />
      </Link>

      <form onSubmit={handleSubmit(onSubmit)}>
        {!!provider && (
          <>
            <input name='provider' value={provider} type='hidden' ref={register} />
            <input name='useProvider' value={true} type='hidden' ref={register} />
          </>
        )}
        <Grid container justify='center' direction='column'>
          <Grid item className={classes.textFieldContainer}>
            {(!!loginError || !!errors.form) && (
              <Typography className={classes.formErrorText}>
                {!!errors.form ? errors.form.message : loginError}
              </Typography>
            )}
          </Grid>
          <Grid item className={classes.textFieldContainer}>
            <TextField
              inputProps={{ 'data-testid': 'email-input' }}
              {...register('email', { required: true })}
              label='Email'
              name='email'
              variant='filled'
              fullWidth
              error={!!errors.email}
              helperText={!!errors.email && errors.email.message}
            />
          </Grid>
          <Grid item className={classes.textFieldContainer}>
            <TextField
              inputProps={{ 'data-testid': 'password-input' }}
              {...register('password', { required: true })}
              label='Password'
              name='password'
              variant='filled'
              type='password'
              error={!!errors.password}
              fullWidth
              helperText={!!errors.password && errors.password.message}
              disabled={!!provider}
            />
            <div className={classes.passwordWrapper}>
              <RouterLink to={'/reset_password'}>Forgot your password?</RouterLink>
            </div>
          </Grid>
          <Grid item className={classes.textFieldContainer}>
            <SubmitButton
              className={classes.submitButton}
              color='primary'
              variant='contained'
              type='submit'
              loading={isSubmitting || isUpdating}
            >
              Login {!!provider && 'with provider'}
            </SubmitButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default LoginView;
