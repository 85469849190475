import { Box } from '@mui/material';
import { useState, useEffect, createRef } from 'react';
import { getChartConfig } from './chartConfig';
import { Chart } from 'chart.js';

const ComplianceChart = ({ index, treatment }) => {
  const chartRef = createRef();
  // TODO: Remove unused code
  // eslint-disable-next-line no-unused-vars
  const [chart, setChart] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [chartData, setChartData] = useState(treatment?.compliance ?? []);
  // eslint-disable-next-line no-unused-vars
  const [compliance, setCompliance] = useState(treatment?.current_compliance);
  // eslint-disable-next-line no-unused-vars
  const [compliant, setCompliant] = useState(compliance >= 70 ? true : false);
  // const [percentDiff, setPercentDiff] = useState(
  //   (100 * (compliance - chartData[1])) / ((compliance + chartData[1]) / 2)
  // );

  useEffect(() => {
    const myChartRef = chartRef.current.getContext('2d');
    const pointBorderColor = chartData.map((x) => (x >= 70 ? 'green' : 'red'));
    const config = getChartConfig({ chartData, compliant, pointBorderColor });
    const newChart = new Chart(myChartRef, config);
    setChart(newChart);

    return () => newChart.destroy();
  }, []);

  return (
    <Box className='chart-container' sx={{ position: 'relative', height: '15vh', width: '100%' }}>
      <canvas id={`compliance-chart}`} ref={chartRef}></canvas>
    </Box>
  );
};

export default ComplianceChart;
