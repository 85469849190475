import { Box } from '@mui/material';
import { useState, useEffect, createRef } from 'react';
import { getChartConfig } from './chartConfig';
import { Chart } from 'chart.js';
import { getBarColor } from '../_utils';

const TherapyUsageSpark = ({ index, chartData }) => {
  const chartRef = createRef();
  const [chart, setChart] = useState(null);

  const getChartProps = (chartData) => {
    const backgroundColor = [];
    const annotations = {};
    let yMax = Math.max(chartData);
    chartData.forEach((val, i) => {
      backgroundColor[i] = getBarColor(val);
      if (!val) {
        annotations['box' + i] = {
          drawTime: 'beforeDraw',
          type: 'box',
          xMin: i - 0.2,
          xMax: i + 0.2,
          yMin: yMax,
          yMax: yMax + 1,
          backgroundColor: 'rgba(0, 0, 0, 0',
          borderColor: 'rgba(255, 99, 132, 1)',
        };
      }
    });

    return { backgroundColor, annotations };
  };

  useEffect(() => {
    const { backgroundColor, annotations } = getChartProps(chartData);
    const myChartRef = chartRef.current.getContext('2d');
    const config = getChartConfig({ chartData, backgroundColor, annotations });
    const newChart = new Chart(myChartRef, config);
    setChart(newChart);

    return () => newChart.destroy();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // better to update the existing chart on page change with new data then to destroy and create a new one
  useEffect(() => {
    if (chart) {
      const { backgroundColor, annotations } = getChartProps(chartData);
      chart.data.datasets[0].data = chartData;
      chart.data.datasets[0].backgroundColor = backgroundColor;
      chart.config.options.plugins.annotation.annotations = annotations;
      chart.update();
    }
  }, [chartData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className='chart-container' sx={{ position: 'relative', height: '5vh', width: '25vh' }}>
      <canvas id={`chart-${index}`} ref={chartRef}></canvas>
    </Box>
  );
};

export default TherapyUsageSpark;
