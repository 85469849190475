const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '300',
  lineHeight: '1.5em',
};

const cardHeader = {
  margin: '-10px 15px 0',
  borderRadius: '3px',
  padding: '10px 15px',
};

const regularCardStyle = (theme) => ({
  card: {
    overflow: 'visible',
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    margin: '25px 0',
    boxShadow: '0 2px 5px 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#fff',
  },
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none',
  },
  cardHeader: {
    margin: '-10px 15px 0',
    borderRadius: '3px',
    padding: '10px 15px',
    background: 'rgb(95, 107, 114)',
    boxShadow:
      '0 10px 20px -12px rgba(95, 107, 114, 0.6), 0 3px 20px 0px rgba(38, 49, 55, 0.4), 0 8px 10px -5px rgba(38, 49, 55, 0.15)',
    transition: 'all 150ms ease 0s',
  },
  cardHeaderHigh: {
    ...cardHeader,
    ...defaultFont,
    marginTop: '-35px',
  },
  cardHeaderLow: {
    ...cardHeader,
    ...defaultFont,
    marginTop: '-15px',
  },
  cardPlainHeader: {
    marginLeft: 0,
    marginRight: 0,
  },
  cardTitle: {
    color: '#FFFFFF',
    marginTop: '0',
    // marginBottom: '5px',
    ...defaultFont,
    alignItems: 'center',
    fontSize: '1.125em',
  },
  cardSubtitle: {
    ...defaultFont,
    marginBottom: '0',
    color: 'rgba(255, 255, 255, 0.62)',
    margin: '0 0 10px',
  },
  cardActions: {
    margin: '0 20px 10px',
    paddingTop: '10px',
    borderTop: '1px solid #eeeeee',
    height: 'auto',
  },
  cardForPatient: {
    overflow: 'visible',
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    margin: '0',
    boxShadow: '0 2px 5px 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#fff',
  },
});

export default regularCardStyle;
