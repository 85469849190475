import { createSelector } from 'reselect';
import moment from 'moment';

const getPatientsState = (state) => state.patients;

const getIsUpdating = createSelector(getPatientsState, (patientsState) => patientsState.isUpdating);

const getQueryCount = createSelector(getPatientsState, (patientsState) => patientsState.queryCount);

const getQueryPages = createSelector(getPatientsState, (patientsState) => patientsState.queryPages);

const getTab = createSelector(getPatientsState, (state) => state.tab);

const getAvailableTabs = createSelector(getPatientsState, (state) => state.availableTabs);

const getSearchState = createSelector(getPatientsState, (patientsState) => patientsState.search);

const getIsSearching = createSelector(getSearchState, (searchState) => searchState.isSearching);

const getSearchResults = createSelector(getSearchState, (searchState) => searchState.patients);

const getQuerySearch = createSelector(getSearchState, (searchState) => searchState.search);

const getCurrent = createSelector(getPatientsState, (patientsState) => patientsState.current);

const getCurrentStats = createSelector(getCurrent, (currentPatient) => currentPatient.stats);

const getCurrentStatsReport = createSelector(
  getCurrentStats,
  (getCurrentStats) => getCurrentStats.report
);

const getCurrentStatsReportNotes = createSelector(
  getCurrentStats,
  (getCurrentStats) => getCurrentStats.report_notes
);

const getCurrentStartTime = createSelector(getCurrent, (currentPatient) => {
  const dateTime = currentPatient.patient.ctime;
  const tzGuess = moment.tz.guess();
  return moment.utc(dateTime).tz(tzGuess).toDate();
});

const getProcessingState = createSelector(
  getPatientsState,
  (patientsState) => patientsState.processing
);

const getIsProcessing = createSelector(
  getProcessingState,
  (processingState) => processingState.isProcessing
);

const getProcessingError = createSelector(
  getProcessingState,
  (processingState) => processingState.error
);

const getError = createSelector(getPatientsState, (patientsState) => patientsState.error);

const getPage = createSelector(getPatientsState, (patientsState) => patientsState.page);

const getPatients = createSelector(getPatientsState, (patientsState) => patientsState.patients);

const getBatchState = createSelector(getPatientsState, (patientsState) => patientsState.batch);

const getIsBatching = createSelector(
  getBatchState,
  (processingState) => processingState.isBatching
);

const getBatchError = createSelector(getBatchState, (processingState) => processingState.error);

const getHasMore = createSelector(getPatientsState, (patientsState) => patientsState.hasMore);

const getCount = createSelector(getPatientsState, (patientsState) => patientsState.count);

const getLastUpdated = createSelector(
  getPatientsState,
  (patientsState) => patientsState.lastUpdated
);

export default {
  getPatientsState,
  getIsUpdating,
  getCurrent,
  getCurrentStats,
  getCurrentStatsReport,
  getCurrentStatsReportNotes,
  getCurrentStartTime,
  getQueryCount,
  getQueryPages,
  getPage,
  getIsSearching,
  getSearchResults,
  getQuerySearch,
  getProcessingState,
  getIsProcessing,
  getProcessingError,
  getError,
  getHasMore,
  getCount,
  getPatients,
  getTab,
  getAvailableTabs,
  getBatchState,
  getIsBatching,
  getBatchError,
  getLastUpdated,
};
