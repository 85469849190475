import axios from 'axios';
import store from 'store';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { BASE_URL } from 'features/authentication/conf';
import {
  refreshAccessToken,
  CSRF_ACCESS_TOKEN,
  CSRF_HEADER,
} from 'features/authentication/auth.api';
import { handleRequestException } from 'features/authentication/_utils';
import qs from 'query-string';

const client = axios.create({
  baseURL: BASE_URL,
  maxRedirects: 5,
  headers: { 'Content-Type': 'application/json' },
  credentials: process.env.NODE_ENV === 'development' ? 'same-origin' : undefined,
  paramsSerializer: (params) => qs.stringify(params),
});
client.defaults.withCredentials = true;

const retryFailedAuthRequest = async (failedRequest) => {
  await refreshAccessToken();
  failedRequest.response.config.headers[CSRF_HEADER] = store.get(CSRF_ACCESS_TOKEN);
};

client.interceptors.request.use((config) => {
  config.headers[CSRF_HEADER] = store.get(CSRF_ACCESS_TOKEN);
  return config;
});

// Disable skip while refreshing b/c if we do Promise.all(<req_1>, <req_2>) when a refresh is due, then one of them
// will succeed while the other fails :\
createAuthRefreshInterceptor(client, retryFailedAuthRequest, { skipWhileRefreshing: false });

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error(error);
      // This will only be called if the auth interceptor above fails
      const { origin } = window.location;
      const goTo = `${origin}`;
      global.location.assign(goTo);
    }
    return handleRequestException(error);
  }
);

export default client;
