import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTaskView } from '../../store/actions';
import { TASKS_ACTION_ICON_TYPES, TASKS_VIEW, taskTypeMapping } from 'common/constants/patient';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Tooltip, Typography, Grid, MenuItem, Menu } from '@mui/material';

const ActionPane = ({ setTaskSummary }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (option) => {
    let formattedSummary = '';
    Object.entries(taskTypeMapping).forEach(([key, value]) => {
      if (value === option) {
        formattedSummary = key;
      }
    });
    setTaskSummary(formattedSummary);
    dispatch(setTaskView({ taskView: TASKS_VIEW.formView }));
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container sx={{ width: '100%', padding: 0 }}>
      <Grid
        container
        justifyContent='end'
        alignItems='center'
        sx={{ width: '100%', padding: 0, margin: '0 auto' }}
        xs={10}
      >
        <Tooltip title='Add Task'>
          <IconButton
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {Object.keys(TASKS_ACTION_ICON_TYPES).map((option, index) => (
            <MenuItem key={option} onClick={() => handleItemClick(option)}>
              <Typography>{`Log ${option}`}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
};

export default ActionPane;
