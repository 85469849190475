import { createReducer, updatingStore, successfulUpdate, failedUpdate } from 'common/utils/utils';

const initialState = {
  isUpdating: false,
  about: {},
  statuses: [],
  softwares: [],
  isDemoMode: false,
  popup: {
    open: false,
    children: {},
  },
  error: '',
};

export const ApplicationActions = {
  INITIALIZE_APPLICATION_REQUEST: 'INITIALIZE_APPLICATION_REQUEST',
  INITIALIZE_APPLICATION_SUCCESS: 'INITIALIZE_APPLICATION_SUCCESS',
  INITIALIZE_APPLICATION_FAILURE: 'INITIALIZE_APPLICATION_FAILURE',

  TOGGLE_DEMO_MODE: 'TOGGLE_DEMO_MODE',
  SET_POPUP: 'SET_POPUP',
};

const applicationReducer = createReducer(initialState, {
  [ApplicationActions.INITIALIZE_APPLICATION_REQUEST]: (state, actions) =>
    updatingStore(state, actions),
  [ApplicationActions.INITIALIZE_APPLICATION_SUCCESS]: (state, actions) =>
    successfulUpdate(state, actions, ['about', 'statuses', 'softwares']),
  [ApplicationActions.INITIALIZE_APPLICATION_FAILURE]: (state, actions) =>
    failedUpdate(state, actions),

  [ApplicationActions.TOGGLE_DEMO_MODE]: (state, action) => ({
    ...state,
    isDemoMode: !state.isDemoMode,
  }),

  [ApplicationActions.SET_POPUP]: (state, action) => {
    const { type, ...rest } = action;
    return {
      ...state,
      popup: { ...rest },
    };
  },
});

export default applicationReducer;
