export const complianceBucketsQuery = {
  model: 'Treatment',
  pipeline: [
    {
      $bucket: {
        groupBy: '$current_compliance',
        boundaries: [
          0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
        ],
        default: 'Null',
        output: {
          count: { $sum: 1 },
          //     "treatments":
          //     {
          //         "$push": {
          //             "name": { "$concat": ["$phi.first_name", " ", "$phi.last_name"] },
          //             "current_compliance": "$current_compliance"
          //         }
          //     }
        },
      },
    },
  ],
};
