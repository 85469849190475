import { makeActionCreator } from 'common/utils/utils';
import { PatientActions } from './reducer';

export const getPatientRequest = makeActionCreator(
  PatientActions.GET_PATIENT_REQUEST,
  'patientId',
  'treatmentId'
);

export const getPatientSuccess = makeActionCreator(PatientActions.GET_PATIENT_SUCCESS, 'patient');

export const getPatientFailure = makeActionCreator(PatientActions.GET_PATIENT_FAILURE, 'error');

export const setTaskView = makeActionCreator(PatientActions.SET_TASK_VIEW, 'taskView');

export const getPatientTasksRequest = makeActionCreator(
  PatientActions.GET_PATIENT_TASKS_REQUEST,
  'patientId',
  'page',
  'filters'
);

export const getPatientTasksRequestSuccess = makeActionCreator(
  PatientActions.GET_PATIENT_TASKS_SUCCESS,
  'tasks',
  'page',
  'count',
  'hasMore'
);

export const getPatientTasksRequestFailure = makeActionCreator(
  PatientActions.GET_PATIENT_TASKS_FAILURE,
  'error'
);

export const setPatientTaskSteps = makeActionCreator(
  PatientActions.SET_PATIENT_TASK_STEP,
  'taskSteps'
);

export const addTaskRequest = makeActionCreator(
  PatientActions.ADD_TASK_REQUEST,
  'task',
  'patientId'
);

export const addTaskSuccess = makeActionCreator(PatientActions.ADD_TASK_SUCCESS, 'task');

export const addTaskFailure = makeActionCreator(PatientActions.ADD_TASK_FAILURE, 'error');

export const updateTaskRequest = makeActionCreator(
  PatientActions.UPDATE_TASK_REQUEST,
  'taskId',
  'updates',
  'patientId'
);

export const updateTaskSuccess = makeActionCreator(
  PatientActions.UPDATE_TASK_SUCCESS,
  'taskId',
  'updates',
  'patientId'
);

export const updateTaskFailure = makeActionCreator(PatientActions.UPDATE_TASK_FAILURE, 'error');

export const setPatientTaskFilterLabel = makeActionCreator(
  PatientActions.SET_PATIENT_TASK_FILTER_LABEL,
  'filterLabel'
);
