import { call, put, takeLatest } from 'redux-saga/effects';
import { TrendsActions } from './reducer';
import {} from './actions';
import { postAggregationQuery } from './api';
import { complianceBucketsQuery } from 'common/constants/mongoQueries';
import { getTrendsSuccess, getTrendsFailure } from './actions';

function* getTrendsRequest({}) {
  try {
    const complianceBucketsRes = yield call(postAggregationQuery, complianceBucketsQuery);
    yield put(
      getTrendsSuccess({
        complianceBuckets: complianceBucketsRes.data.data,
        loaded: true,
      })
    );
  } catch (e) {
    yield put(
      getTrendsFailure({
        error: e.message,
        loaded: true,
      })
    );
  }
}

function* trendsSagas() {
  yield takeLatest(TrendsActions.GET_TRENDS_REQUEST, getTrendsRequest);
}

export default trendsSagas;
