import React from 'react';
// import './assets/css/material-dashboard-react.css?v=1.2.0';
// import 'utils/_moment';
import ensoTherapyTheme from 'common/themes/enso-therapy-theme';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import MomentUtils from '@date-io/moment';
import { AppDataProvider } from 'features/providers';
// import { createTheme, MuiThemeProvider } from '@mui/material';
// import { createTheme, MuiThemeProvider ,  responsiveFontSizes, } from '@mui/material/styles';
// import {
//   ThemeProvider,
//   StyledEngineProvider,
//   CssBaseline,
// } from '@mui/material';
// import {
//   responsiveFontSizes,
// } from '@mui/material/styles'
// import { MuiPickersUtilsProvider } from '@mui/material/pickers';
import { ThemeProvider } from '@mui/material';
import { Provider as ReduxProvider } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import { LoginView, ResetPasswordView } from './features/authentication';
// import { LoginView } from './features/authentication';
import { AppErrorBoundary } from 'features/authentication/Boundaries/error';
import AuthenticatedRoute from 'features/authentication/AuthenticatedRoute';
import store from 'common/store/store';
import { setUpNotifications } from 'reapop';
// import createCache from '@emotion/cache';
// import { CacheProvider } from "@emotion/react";
import PatientsView from 'features/patients/PatientsView/PatientsView';
import PatientView from 'features/patient/PatientView/PatientView';
import Navigation from 'features/navigation/Navigation/Navigation';
import TrendsView from 'features/trends/TrendsView/TrendsView';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
/**
 * Starting to import new state management from Recoil Root
 */
import { RecoilRoot } from 'recoil';

Chart.register(annotationPlugin);

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const theme = createTheme(ensoTherapyTheme);

const Page404 = ({ location }) => (
  <div>
    <h3>
      <code>{location.pathname}</code> not found
    </h3>
  </div>
);

const App = () => {
  setUpNotifications({
    defaultProps: {
      position: 'top-right',
      dismissible: true,
      dismissAfter: 5000,
    },
  });

  // useEffect(() => {
  //   ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  // }, []);

  return (
    <RecoilRoot>
      <ReduxProvider store={store}>
        {/* <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}> */}
        {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            {/* For dark mode compatibility */}
            <CssBaseline />
            <AppErrorBoundary>
              <AppDataProvider>
                <Routes>
                  <Route path='/' element={<Navigate to='/patients' />} />
                  <Route element={<ResetPasswordView />} path={'/reset_password'} />
                  {/* <Route element={<ConfirmationView/>} path={'/confirm/:token'} />
                  <Route element={<ChangePasswordView/>} path={'/change_password/:token'} /> */}
                  <Route
                    element={
                      <Navigation>
                        <AuthenticatedRoute />{' '}
                      </Navigation>
                    }
                  >
                    <Route path='/patients' element={<PatientsView />} />
                    <Route path='/trends' element={<TrendsView />} />
                    <Route
                      path='/patients/:patientId/treatment/:treatmentId'
                      element={<PatientView />}
                    />
                  </Route>

                  <Route element={<LoginView />} path='/login' />
                  <Route path='*' component={Page404} />
                </Routes>
              </AppDataProvider>
            </AppErrorBoundary>
          </ThemeProvider>
        </CacheProvider>
        {/* </MuiPickersUtilsProvider> */}
        {/* </MuiThemeProvider>
        </StylesProvider> */}
      </ReduxProvider>
    </RecoilRoot>
  );
};

export default App;
