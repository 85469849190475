import client from 'common/api/client';

export const getPatient = (id) => {
  const endpoint = `/internal/patient/${id}`;
  return client.get(endpoint);
};

export const getPatientTreatment = (id) => {
  const endpoint = `/treatment/${id}`;
  return client.get(endpoint);
};

export const getTreatmentSessions = (id) => {
  const endpoint = `/treatment/${id}/sessions`;
  return client.get(endpoint);
};

// export const getActivities = (id, updates) => {
//     const endpoint = `/activity/{id}`;
//     return client.patch(endpoint, updates);
// };

export const getPatientTasks = (patientId, query) => {
  const endpoint = `/task?patient=${patientId}`;
  return client.get(endpoint, {
    params: query,
  });
};

export const getTaskSteps = (id) => {
  const endpoint = `/task/${id}`;
  return client.get(endpoint);
};

export const postPatientTask = (res) => {
  const endpoint = `/task`;
  return client.post(endpoint, {
    ...res,
  });
};

export const patchPatientTask = ({ id, updates }) => {
  const endpoint = `/task/${id}`;
  return client.patch(endpoint, updates);
};
