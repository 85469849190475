import { createSelector } from 'reselect';
import { wrapItInAMoment } from 'common/utils/_moment';

const getPatientState = (state) => state.patient;

const getPatient = createSelector(getPatientState, (patientState) => patientState.patient);

const getPatientTreatment = createSelector(
  getPatientState,
  (patientState) => patientState.treatment
);

const getTreatmentSessions = createSelector(getPatientState, (patientState) => {
  return patientState.sessions?.sort(
    (a, b) =>
      wrapItInAMoment(b?.start_date).format('YYYYMMDD') -
      wrapItInAMoment(a?.start_date).format('YYYYMMDD')
  );
});

const getLoaded = createSelector(getPatientState, (patientState) => patientState.loaded);

// const getFlattenedPhi = createSelector(
//   getPatientState,
//   (patientState) => {
//     return Object.assign({}, ...function _flatten(o) { return [].concat(...Object.keys(o).map(k => typeof o[k] === 'object' ? _flatten(o[k]) : ({ [k]: o[k] }))) }(patientState.treatment?.phi))
//   }

// );

const getTasks = createSelector(getPatientState, (patientState) => patientState.tasks);

const getCompletedTasks = createSelector(getTasks, (tasks) => {
  return tasks
    .filter((task) => task.completed)
    .sort((a, b) => {
      let dateCompletedA = new Date(a.completed);
      let dateCompletedB = new Date(b.completed);
      return dateCompletedA - dateCompletedB;
    });
});
const getUncompletedTasks = createSelector(
  getTasks,

  (tasks) => {
    return tasks
      .filter((task) => task.completed === null)
      .sort((a, b) => {
        let dateDueA = new Date(a.due);
        let dateDueB = new Date(b.due);
        return dateDueA - dateDueB;
      });
  }
);

const getTaskCount = createSelector(getPatientState, (patientState) => patientState.count);

const getTaskPage = createSelector(getPatientState, (patientState) => patientState.page);

const getTaskMode = createSelector(getPatientState, (patientState) => patientState.taskMode);

const getPatientTaskSteps = createSelector(
  getPatientState,
  (patientState) => patientState.taskSteps
);

const getPatientDataLoaded = createSelector(getPatientState, (patientState) => patientState.loaded);

const getPatientTasksLoaded = createSelector(
  getPatientState,
  (patientState) => patientState.tasksLoaded
);

const getPatientTasksFilterLabel = createSelector(
  getPatientState,
  (patientState) => patientState.filterLabel
);

const getTaskView = createSelector(getPatientState, (patientState) => patientState.taskView);

export default {
  getPatient,
  getPatientTreatment,
  getTreatmentSessions,
  getLoaded,
  getTaskMode,
  getUncompletedTasks,
  getPatientTaskSteps,
  getTasks,
  getCompletedTasks,
  getTaskCount,
  getTaskPage,
  getPatientDataLoaded,
  getPatientTasksLoaded,
  getPatientTasksFilterLabel,
  getTaskView,
  // getFlattenedPhi
};
