import { Grid, Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPatientRequest } from 'features/patient/store/actions';
import { useSelector } from 'react-redux';
import patientSelectors from 'features/patient/store/selectors';
import { formatName } from 'common/utils/utils';
import TherapyUsageChart from '../TherapyUsageChart/TherapyUsageChart';
import ComplianceChart from '../ComplianceChart/ComplianceChart';
import ActivityChart from '../ActivityChart/ActivityChart';
import LoadingPineapple from 'common/components/LoadingPineapple/LoadingPineapple';
import { flattenObject } from 'common/utils/utils';
import TasksPanes from '../TasksPane/TasksPane';

const PHI_FIELDS = [
  'current_compliance',
  'compliance_change',
  'risk_predictions',
  'dob',
  'gender',
  'deviceName',
  'deviceNumber',
  'fgSerial',
  'moduleSerial',
  'rampEnable',
  'rampTime',
  'setPressure',
  'startPressure',
  'therapyMode',
  'leak_limit',
  'mrn',
  'serialNumber',
];

const PHI_FIELDS_NAMES = {
  current_compliance: 'Current Compliance',
  compliance_change: 'Compliance Change',
  risk_predictions: 'Risk Predictions',
  dob: 'DOB',
  gender: 'Gender',
  deviceName: 'Device Name',
  deviceNumber: 'Device Number',
  fgSerial: 'Fg Serial',
  moduleSerial: 'Module Serial',
  rampEnable: 'Ramp Enable',
  rampTime: 'Ramp Time',
  setPressure: 'Set Pressure',
  startPressure: 'Start Pressure',
  therapyMode: 'Therapy Mode',
  leak_limit: 'Leak Limit',
  mrn: 'MRN',
  serialNumber: 'Serial Number',
};

// TODO function to reformat format Field Name dynamically with regex
// const formatFieldName = (key) => {};

const PatientView = ({}) => {
  const dispatch = useDispatch();
  const { patientId, treatmentId } = useParams();
  const treatment = useSelector(patientSelectors.getPatientTreatment);
  const clinic = treatment?.clinic;
  const sessions = useSelector(patientSelectors.getTreatmentSessions);
  const loaded = useSelector(patientSelectors.getLoaded);
  const tasks = useSelector(patientSelectors.getTasks);
  const [flattenedTreatment, setFlattenedTreatment] = useState({});

  const Item = styled(Paper)(({ theme }) => {
    return {
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
      height: '100%',
      width: '100%',
    };
  });

  useEffect(() => {
    dispatch(getPatientRequest({ patientId, treatmentId }));
  }, []);

  useEffect(() => {
    const phi = treatment ?? {};
    setFlattenedTreatment(flattenObject(phi));
  }, [loaded]);

  if (!loaded) {
    return <LoadingPineapple shouldSpin={true} />;
  }

  return (
    <Box sx={{ flexGrow: 1, margin: '1rem' }} data-testid='patient-view'>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          {/* <Item> */}
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={1}
            // sx={{ height: '100&' }}
          >
            <Grid item xs={4} sx={{ height: '100%', width: '100%' }}>
              <Item sx={{ padding: '1rem', height: '25vh' }}>
                <Box
                  display='flex'
                  flexDirection='column'
                  flexWrap='wrap'
                  sx={{ height: '100%', width: '100%' }}
                >
                  {/* <Box display='flex' flexDirection='column' > */}
                  <Box display='flex' flexDirection='row' justifyContent='left' alignItems='center'>
                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                      Name:&nbsp;
                    </Typography>
                    <Typography variant='body2'>
                      {formatName(treatment?.phi?.first_name, treatment?.phi?.last_name)}
                    </Typography>
                  </Box>
                  {PHI_FIELDS.map((field) => {
                    if (flattenedTreatment?.[field]) {
                      return (
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='left'
                          alignItems='center'
                          key={`field-${field}`}
                        >
                          <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                            {PHI_FIELDS_NAMES[field]}:&nbsp;
                          </Typography>
                          <Typography variant='body2'> {flattenedTreatment?.[field]}</Typography>
                        </Box>
                      );
                    }
                    return field;
                  })}
                </Box>
                {/* </Box> */}
              </Item>
            </Grid>
            <Grid item xs={8} sx={{ height: '100%', width: '100%' }}>
              <Item>
                <Grid
                  container
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  spacing={1}
                  // sx={{ paddingBottom: '1rem',paddingTop:'1rem' }}
                  sx={{ padding: '1rem', height: '65vh' }}
                >
                  <ComplianceChart treatment={treatment} />
                  <TherapyUsageChart sessions={sessions} usage={treatment?.usage} />
                  <ActivityChart tasks={tasks} />
                </Grid>
              </Item>
            </Grid>
          </Grid>
          {/* </Item> */}
        </Grid>
        <Grid item xs={6} sx={{ height: '100%', width: '100%' }}>
          <Item sx={{ height: '90vh', width: '100%', margin: '0' }}>
            <TasksPanes patientId={patientId} clinicId={clinic} />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientView;
