import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
// import annotationPlugin from 'chartjs-plugin-annotation';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-moment';
import moment from 'moment';
// import { CollectionsBookmarkRounded, CssTwoTone, Fastfood } from '@mui/icons-material';
// import Icon from '@mui/material/Icon';
// import MdPhone from '@mui/icons-material/Phone';
// import Chip from '@mui/material/Chip';

Chart.register(annotationPlugin);

const imageSrc = {
  call: process.env.PUBLIC_URL + '/assets/svg/call.svg',
  email: process.env.PUBLIC_URL + '/assets/svg/email.svg',
  text: process.env.PUBLIC_URL + '/assets/svg/message.svg',
  note: process.env.PUBLIC_URL + '/assets/svg/note.svg',
  warning: process.env.PUBLIC_URL + '/assets/svg/warning.svg',
};

const images = {};
Object.entries(imageSrc).forEach(([key, src]) => {
  const newImg = new Image();
  newImg.onload = function () {
    images[key] = newImg;
  };
  newImg.src = src;
});
export const getChartConfig = ({ chartData }) => ({
  type: 'bar',
  data: {
    labels: [...Array(30).keys()].map((i) => moment().utc().subtract(i, 'd').format('M/D')),
    datasets: chartData.map((session, i) => ({ data: session, ...barConfig })),
  },
  plugins: [customDataLabels],
  options: {
    responsive: true,
    maintainAspectRatio: false,
    // normalized: true,
    // parsing: false,
    // animation: false,
    // plugins:[ChartDataLabels,customDataLabels],
    plugins: {
      // annotation: {
      //     annotations: {
      //         line: {
      //             type: 'line',
      //             yMin: 4,
      //             yMax: 4,
      //             borderColor: 'rgb(255, 99, 132)',
      //             borderWidth: 1,
      //         }
      //     }
      // },
      tooltip: {
        callbacks: {
          label: ({ chart, datasetIndex, dataIndex }) => {
            return chart.metaData[datasetIndex][dataIndex].created;
          },
        },
      },
      // labels:{
      //     render: 'image',
      //     images: []
      // },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        reverse: true,
        afterFit: function (scaleInstance) {
          scaleInstance.width = 100;
        },
        beginAtZero: true,
        display: true,
        stacked: true,
        // barThickness: 20
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          stepSize: 2,
          autoSkip: false,
          font: {
            size: 12,
          },
        },
      },
      y: {
        // min: 0,
        // max: 24,
        afterFit: function (scaleInstance) {
          scaleInstance.width = 40;
        },
        beginAtZero: true,
        display: true,
        // stacked: true,
        // type: 'time',
        // time: {
        //     unit: 'day'
        // }
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          // reverse: true,
          font: {
            size: 10,
          },
          // stepSize: 4,
          autoSkip: true,
          callback: function (label, index, labels) {
            let l = label;
            if (l > 24) l -= 24;
            return moment(parseFloat(l), ['HH:mm']).format('ha');
          },
        },
      },
    },
  },
});

const customDataLabels = {
  id: 'customDataLabels',
  beforeDatasetsDraw(chart, args, pluginOptions) {
    const {
      ctx,
      data,
      // TODO: Remove unused code
      // eslint-disable-next-line no-unused-vars
      chartArea: { top, bottom, left, right, width, height },
      // eslint-disable-next-line no-unused-vars
      scales: { x, y },
      metaData,
    } = chart;
    ctx.save();
    ctx.font = 'bold 12px FontAwesome';
    ctx.fillStyle = 'black';
    // let i = 0
    data.datasets.forEach((dataset, i) => {
      // let j = 0
      dataset.data.forEach((dataset, j) => {
        if (!isNaN(chart.getDatasetMeta(i).data[j].height)) {
          ctx.drawImage(
            images[metaData[i][j].type],
            chart.getDatasetMeta(i).data[j].x - 8,
            chart.getDatasetMeta(i).data[j].y - 5,
            15,
            15
          );
          j += 1;
        }
      });
    });
  },
};

const barConfig = {
  // barThickness: 30,
  // barPercentage: 1,
  // categoryPercentage: 1,
  backgroundColor: 'rgba(0, 0, 0, 0)',
};
