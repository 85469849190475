import { createReducer } from 'common/utils/utils';
import { tzMoment } from 'common/utils/_moment';
import { SORT_DIRECTION } from 'common/constants/filters';

const defaultDateEnd = tzMoment().endOf('day');
const defaultDateStart = tzMoment().subtract(1, 'month').startOf('day');

export const initialState = {
  dateStart: defaultDateStart,
  dateEnd: defaultDateEnd,
  params: {
    clinics: [],
    computers: [],
    studyTypes: [],
    softwares: [],
    state: [],
    assignee: [],
    currentAssignee: [],
  },
  searchInput: '',
  searchFields: [],
  // Anything specified in requiredParams overrides what is in params
  // This can be useful to limit filtering to a certain user, for example
  requiredParams: {},
  sortOptions: {
    current_compliance: { sortColumn: 'current_compliance', sortDirection: SORT_DIRECTION.DESC },
  },
};

export const FiltersActions = {
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  UPDATE_DATES: 'UPDATE_DATES',
  RESET_FILTER_PARAMS: 'RESET_FILTER_PARAMS',
  UPDATE_SORT_OPTIONS: 'UPDATE_SORT_OPTIONS',
  INIT_SORT_OPTIONS: 'INIT_SORT_OPTIONS',
  CLEAR_SORT_OPTIONS: 'CLEAR_SORT_OPTIONS',
  SET_REQUIRED_PARAMS: 'SET_REQUIRED_PARAMS',
  UPDATE_SEARCH_INPUT: 'UPDATE_SEARCH_INPUT',
  CLEAR_SEARCH_INPUT: 'CLEAR_SEARCH_INPUT',
  UPDATE_SEARCH_FIELDS: 'UPDATE_SEARCH_FIELDS',
  CLEAR_SEARCH_FIELDS: 'CLEAR_SEARCH_FIELDS',
};

const filtersReducer = createReducer(initialState, {
  [FiltersActions.UPDATE_FILTERS]: (state, actions) => {
    const { type, ...rest } = actions;
    return {
      ...state,
      params: {
        ...state.params,
        ...rest,
      },
    };
  },
  [FiltersActions.UPDATE_DATES]: (state, actions) => ({
    ...state,
    ...actions,
  }),
  [FiltersActions.RESET_FILTER_PARAMS]: (state, actions) => ({
    ...state,
    params: {
      ...initialState.params,
    },
  }),
  [FiltersActions.UPDATE_SORT_OPTIONS]: (state, actions) => {
    return {
      ...state,
      sortOptions: { ...state.sortOptions, [actions.sortOptions.sortColumn]: actions.sortOptions },
    };
  },
  [FiltersActions.INIT_SORT_OPTIONS]: (state, actions) => {
    return {
      ...state,
      sortOptions: actions.sortOptions,
    };
  },
  [FiltersActions.CLEAR_SORT_OPTIONS]: (state, actions) => {
    return {
      ...state,
      sortOptions: {},
    };
  },
  [FiltersActions.SET_REQUIRED_PARAMS]: (state, { requiredParams }) => ({
    ...state,
    requiredParams,
  }),
  [FiltersActions.UPDATE_SEARCH_INPUT]: (state, { searchInput }) => {
    return {
      ...state,
      searchInput,
    };
  },
  [FiltersActions.CLEAR_SEARCH_INPUT]: (state) => {
    return {
      ...state,
      searchInput: '',
    };
  },
  [FiltersActions.UPDATE_SEARCH_FIELDS]: (state, { searchFields }) => {
    return {
      ...state,
      searchFields,
    };
  },
  [FiltersActions.CLEAR_SEARCH_FIELDS]: (state) => {
    return {
      ...state,
      searchFields: [],
    };
  },
});

export default filtersReducer;
