import React from 'react';
import Note from '@mui/icons-material/TextSnippet';
import PropTypes from 'prop-types';

const NoteIcon = ({ size }) => {
  return (
    <div data-testid='note-icon'>
      <Note fontsize={size} />
    </div>
  );
};

NoteIcon.propTypes = {
  size: PropTypes.string,
};

export default NoteIcon;
