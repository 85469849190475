import { createReducer } from 'common/utils/utils';
import { TASKS_VIEW } from 'common/constants/patient';
const initialState = {
  patient: {},
  loaded: false,
  treatment: {},
  sessions: [],
  tasks: [],
  count: 0,
  page: 0,
  filterLabel: 'all',
  taskView: TASKS_VIEW.listView,
};

export const PatientActions = {
  GET_PATIENT_REQUEST: 'GET_PATIENT_REQUEST',
  GET_PATIENT_SUCCESS: 'GET_PATIENT_SUCCESS',
  GET_PATIENT_FAILURE: 'GET_PATIENT_FAILURE',
  ADD_TASK_REQUEST: 'ADD_TASK_REQUEST',
  ADD_TASK_SUCCESS: 'ADD_TASK_SUCCESS',
  ADD_TASK_FAILURE: 'ADD_TASK_FAILURE',
  UPDATE_TASK_REQUEST: 'UPDATE_TASK_REQUEST',
  UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
  UPDATE_TASK_FAILURE: 'UPDATE_TASK_FAILURE',
  SET_TASK_VIEW: 'SET_TASK_VIEW',
  GET_PATIENT_TASKS_REQUEST: 'GET_PATIENT_TASKS_REQUEST',
  GET_PATIENT_TASKS_SUCCESS: 'GET_PATIENT_TASKS_SUCCESS',
  GET_PATIENT_TASKS_FAILURE: 'GET_PATIENT_TASKS_FAILURE',
  GET_PATIENT_TASKS_BY_ID: 'GET_PATIENT_TASKS_BY_ID',
  SET_PATIENT_TASK_STEP: 'SET_PATIENT_TASK_STEP',
  SET_PATIENT_TASK_FILTER_LABEL: 'SET_PATIENT_TASK_FILTER_LABEL',
};

const patientReducer = createReducer(initialState, {
  [PatientActions.GET_PATIENT_REQUEST]: (state) => ({
    ...state,
    ...initialState,
    loaded: false,
    tasksLoaded: false,
  }),
  [PatientActions.GET_PATIENT_SUCCESS]: (state, actions) => ({
    ...state,
    ...actions.patient,
    loaded: true,
    tasksLoaded: true,
  }),
  [PatientActions.GET_PATIENT_FAILURE]: (state, actions) => ({
    ...state,
    error: actions.error,
    loaded: true,
  }),

  [PatientActions.ADD_TASK_REQUEST]: (state, actions) => {
    return {
      ...state,
      tasksLoaded: false,
    };
  },
  [PatientActions.ADD_TASK_SUCCESS]: (state, actions) => {
    return {
      ...state,
      tasksLoaded: true,
    };
  },
  [PatientActions.ADD_TASK_FAILURE]: (state, actions) => ({
    ...state,
    tasksLoaded: true,
    error: actions.error,
  }),

  [PatientActions.UPDATE_TASK_REQUEST]: (state, actions) => {
    return {
      ...state,
    };
  },
  [PatientActions.UPDATE_TASK_SUCCESS]: (state, actions) => {
    return {
      ...state,
    };
  },
  [PatientActions.UPDATE_TASK_FAILURE]: (state, actions) => ({
    ...state,
    error: actions.error,
  }),
  [PatientActions.SET_TASK_VIEW]: (state, action) => ({
    ...state,
    taskView: action.taskView,
  }),
  [PatientActions.GET_PATIENT_TASKS_REQUEST]: (state, actions) => ({
    ...state,
    tasksLoaded: false,
  }),
  [PatientActions.GET_PATIENT_TASKS_SUCCESS]: (state, actions) => ({
    ...state,
    tasks: actions.tasks,
    count: actions.count,
    page: actions.page,
    hasMore: actions.hasMore,
    tasksLoaded: true,
  }),
  [PatientActions.GET_PATIENT_TASKS_FAILURE]: (state, actions) => ({
    ...state,
    tasksLoaded: true,
  }),
  [PatientActions.GET_PATIENT_TASKS_SUCCESS]: (state, actions) => ({
    ...state,
    tasks: actions.tasks,
    taskRequestSuccess: true,
    count: actions.count,
    page: actions.page,
    hasMore: actions.hasMore,
    tasksLoaded: true,
  }),
  [PatientActions.GET_PATIENT_TASKS_FAILURE]: (state, actions) => ({
    ...state,
    tasksLoaded: true,
  }),
  [PatientActions.SET_PATIENT_TASK_STEP]: (state, action) => ({
    ...state,
    taskSteps: action.taskSteps,
  }),
  [PatientActions.SET_PATIENT_TASK_FILTER_LABEL]: (state, action) => ({
    ...state,
    filterLabel: action.filterLabel,
  }),
});

export default patientReducer;
