import { ApplicationActions } from './reducer';
import { makeActionCreator } from 'common/utils/utils';

export const initializeApplicationRequest = makeActionCreator(
  ApplicationActions.INITIALIZE_APPLICATION_REQUEST
);
export const initializeApplicationSuccess = makeActionCreator(
  ApplicationActions.INITIALIZE_APPLICATION_SUCCESS,
  'about',
  'statuses',
  'softwares'
);
export const initializeApplicationFailure = makeActionCreator(
  ApplicationActions.INITIALIZE_APPLICATION_FAILURE,
  'error'
);

export const toggleDemoMode = makeActionCreator(ApplicationActions.TOGGLE_DEMO_MODE);

export const setPopup = makeActionCreator(
  ApplicationActions.SET_POPUP,
  'open',
  'onClose',
  'children'
);
