import { AuthenticationActions } from './reducer';
import { makeActionCreator } from 'common/utils/utils';

export const loginRequest = makeActionCreator(
  AuthenticationActions.LOGIN_REQUEST,
  'email',
  'password'
);
export const loginSuccess = makeActionCreator(
  AuthenticationActions.LOGIN_SUCCESS,
  'isAuthenticated'
);
export const loginFailure = makeActionCreator(AuthenticationActions.LOGIN_FAILURE, 'error');

export const superLoginRequest = makeActionCreator(
  AuthenticationActions.SUPER_LOGIN_REQUEST,
  'email',
  'password'
);
export const superLoginSuccess = makeActionCreator(
  AuthenticationActions.SUPER_LOGIN_SUCCESS,
  'isSuperUser'
);
export const superLoginFailure = makeActionCreator(
  AuthenticationActions.SUPER_LOGIN_FAILURE,
  'error'
);

export const refreshProviderDomainsRequest = makeActionCreator(
  AuthenticationActions.REFRESH_PROVIDER_DOMAINS_REQUEST
);
export const refreshProviderDomainsSuccess = makeActionCreator(
  AuthenticationActions.REFRESH_PROVIDER_DOMAINS_SUCCESS,
  'providerDomains'
);
export const refreshProviderDomainsFailure = makeActionCreator(
  AuthenticationActions.REFRESH_PROVIDER_DOMAINS_FAILURE,
  'error'
);

export const refreshAuthRequest = makeActionCreator(AuthenticationActions.REFRESH_AUTH_REQUEST);
export const refreshAuthSuccess = makeActionCreator(
  AuthenticationActions.REFRESH_AUTH_SUCCESS,
  'isAuthenticated'
);
export const refreshAuthFailure = makeActionCreator(AuthenticationActions.REFRESH_AUTH_FAILURE);

export const logoutRequest = makeActionCreator(AuthenticationActions.LOGOUT_REQUEST, 'navigate');
export const logoutSuccess = makeActionCreator(
  AuthenticationActions.LOGOUT_SUCCESS,
  'isAuthenticated'
);
export const logoutFailure = makeActionCreator(AuthenticationActions.LOGOUT_FAILURE, 'error');

export const clearErrorsRequest = makeActionCreator(AuthenticationActions.CLEAR_ERRORS);

export const initAuthentication = makeActionCreator(AuthenticationActions.INIT_AUTHENTICATION);
