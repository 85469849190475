import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

class ErrorBoundary extends Component {
  state = {
    inError: false,
  };

  static getDerivedStateFromError(error) {
    return { inError: true };
  }

  componentDidCatch(error, info) {
    console.error({ error, info });
  }

  render() {
    const { children } = this.props;
    const { inError } = this.state;
    return inError ? (
      <div
        style={{
          height: '100%',
          width: '100%',
          // maxWidth: 300,
          display: 'flex',
          justifyContent: 'center',
          flexFlow: 'column nowrap',
          alignItems: 'center',
        }}
      >
        <Typography variant={'caption'} align={'center'} gutterBottom={true}>
          There was a problem displaying this component.
        </Typography>
      </div>
    ) : (
      children
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  forceRerender: PropTypes.bool,
};

export default ErrorBoundary;
