import React from 'react';
import { Tooltip, Button } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SORT_DIRECTION } from 'common/constants/filters';
import { useClasses } from 'common/utils/utils';

const styles = {
  button: {
    minWidth: 0,
    height: '30.75px',
    textTransform: 'none',
    color: 'black',
    '&:hover, &:focus, &:active, &:visited': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  icon: {
    fontSize: '20px',
    marginLeft: '.3rem',
  },
};

const SortDirectionButton = ({
  label,
  column,
  direction,
  onChange,
  popup = { 0: 'sort', 1: 'asc', 2: 'desc' },
  reverse = false,
  ...other
}) => {
  let DirectionIcon = direction === SORT_DIRECTION.ASC ? ExpandLessIcon : ExpandMoreIcon;
  if (reverse) {
    popup = { 0: 'sort', 2: 'asc', 1: 'desc' };
    DirectionIcon = direction === SORT_DIRECTION.ASC ? ExpandMoreIcon : ExpandLessIcon;
  }

  const classes = useClasses(styles);
  const toggleDirection = (direction) => {
    let newDirection = direction === 2 ? (direction = 0) : (direction += 1);
    return newDirection;
  };

  const handleClick = (e) => {
    const newDirection = toggleDirection(direction);
    onChange && onChange(column, newDirection);
  };

  return (
    <Tooltip title={popup[direction]}>
      <Button className={classes.button} onClick={handleClick} variant='text' {...other}>
        {' '}
        {label} {direction !== 0 && <DirectionIcon className={classes.icon} />}
      </Button>
    </Tooltip>
  );
};

export default SortDirectionButton;
