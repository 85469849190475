import { createSelector } from 'reselect';

const getTrendsState = (state) => state.trends;

const getComplianceBuckets = createSelector(getTrendsState, (trendsState) => {
  return trendsState?.complianceBuckets;
});

const getLoaded = createSelector(getTrendsState, (trendsState) => trendsState?.loaded);

export default {
  getComplianceBuckets,
  getLoaded,
};
