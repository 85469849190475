import React, { useEffect, useState } from 'react';
import { AppBar, Tab, Tabs, Box, Toolbar } from '@mui/material';
import { AssignmentIndSharp } from '@mui/icons-material';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { styled } from '@mui/material/styles';
// import { ErrorBoundary } from 'components/molecules';
// import { AppToolbar } from 'components/organisms';
// import Popup from 'components/organisms/popup/Popup';
import {
  //   Link,
  useLocation,
  //   Switch,
  //   Route,
  //   Redirect,
  //   useHistory,
} from 'react-router-dom';
// import { getDashboardTabs, PATIENTS_TAB } from './tabs';
// import { buildPatientsPath } from '../utils/paths';
// import ReactGA from 'react-ga';
// import selectors from '../store/user/selectors';
// import applicationSelectors from '../store/application/selectors';
// import { LoadingPineapple } from '../components/atoms';
// import { useRef } from 'react';
import { useNavigate } from 'react-router';
import UserPopover from './UserPopover';
import { logoSrc } from 'common/assets';
import { useClasses } from 'common/utils/utils';

const styles = {
  appToolbar: {
    height: 50,
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  appBarMenu: {
    color: '#000',
    // padding: 10,
    minWidth: 85,
    flex: `0 1 auto`,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appBarLogo: {
    position: 'relative',
    flex: '0 0 auto',
    // padding: '0 20px 0 0',
    marginLeft: 20,
    marginRight: 5,
    zIndex: '4',
    '& img': {
      padding: 5,
      width: 200,
      verticalAlign: 'baseline',
      border: '0',
    },
  },
  appBarNavigation: {
    // height: '100%',
    flex: '1 1 auto',
    // width: '100%',
    minWidth: 300,
    position: 'relative',
  },
  appBarAccount: {
    paddingLeft: 20,
    paddingRight: 20,
    flex: '0 0 auto',
  },
};

const StyledTab = styled(Tab)(({ theme }) => {
  return {
    zIndex: 10,
    minHeight: '3rem',
    wrapper: {
      flexDirection: 'row',
      justifyContent: 'normal',
      alignItems: 'normal',
      width: 'auto',
    },
    labelIcon: {
      minHeight: 0,
    },
  };
});

/**
 * A functional React component as a wrapper for a page with navigation.
 * Renders the page's navbar and content
 */
function Navigation(props) {
  const [currentTab, setCurrentTab] = useState('patients');
  // const match = useRouteMatch();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useClasses(styles);

  useEffect(() => {
    // TODO revist this later when more tabs are added
    // const parsedMatchedPath = match.path.split('/').pop();
    const splitLocation = location.pathname.split('/');
    // const tabPathIndex = splitLocation.indexOf(parsedMatchedPath);
    // const parsedPath = splitLocation[tabPathIndex + 1];
    // console.log('parsedMatchedPath',parsedMatchedPath)
    if (splitLocation.length === 2) {
      setCurrentTab(splitLocation[1]);
    } else {
      setCurrentTab('');
    }
  }, [location.pathname]);

  const getNavigationTabs = (props) => {
    return [
      {
        id: 'patients',
        dataTestId: 'patients-nav-tabs',
        // component: PatientRoutes,
        label: 'Patients',
        icon: <AssignmentIndSharp />,
        route: '/patients',
      },
      {
        id: 'trends',
        dataTestId: 'trends-nav-tabs',
        label: 'Trends',
        icon: <InsertChartIcon />,
        route: '/trends',
      },
    ];
  };

  const handleTabs = (event, route) => {
    navigate('/' + route);
  };

  return (
    <Box data-testid='navigation-tabs'>
      <AppBar sx={{ height: '3rem', backgroundColor: 'white' }}>
        <Toolbar disableGutters={true} className={classes.appToolbar} variant={'regular'}>
          <a className={classes.appBarLogo} href={'https://ensodata.com'}>
            <img style={{ width: '100px' }} src={logoSrc} alt='EnsoData' />
          </a>
          <div className={classes.appBarNavigation}>
            <Tabs
              sx={{ height: '2rem' }}
              value={currentTab}
              onChange={handleTabs}
              indicatorColor='primary'
              textColor='primary'
              variant='standard'
              scrollButtons={false}
              // classes={{
              //     scrollable: classes.scrollable,
              // }}
              centered
            >
              {getNavigationTabs().map((tab) => {
                return (
                  <StyledTab
                    // onClick={(event, value) => { handleTabs(value) }}
                    key={`tab-${tab.id}`}
                    value={tab.id}
                    iconPosition='start'
                    icon={
                      <div style={{ marginRight: '5px', marginBottom: 0, height: '1.5rem' }}>
                        {tab.icon}
                      </div>
                    }
                    label={tab.label}
                    data-testid={tab.dataTestId}
                  />
                );
              })}
            </Tabs>
          </div>
          <div className={classes.appBarAccount}>
            <UserPopover />
            {/* <InfoPopover /> */}
          </div>
        </Toolbar>
      </AppBar>
      <Box sx={{ marginTop: '3.5rem', height: '100' }}>
        {
          /** show as content whatever we provide */
          props.children
        }
      </Box>
    </Box>
  );
}

export default Navigation;
