export const ADMIN = 'admin';
export const SUPER = 'super';
export const USER = 'user';
export const SIMULATOR = 'simulator';

export const testRole = (userRoles) => {
  if (userRoles.includes(SUPER)) {
    return SUPER;
  } else if (userRoles.includes(ADMIN)) {
    return ADMIN;
  } else {
    return USER;
  }
};
