import React from 'react';
import { Grid } from '@mui/material';
import { useClasses } from 'common/utils/utils';

const styles = {
  grid: {
    padding: '0 15px !important',
  },
};

const ItemGrid = ({ ...props }) => {
  const { children, ...rest } = props;
  const classes = useClasses(styles);
  return (
    <Grid item {...rest} className={classes.grid}>
      {children}
    </Grid>
  );
};

export default ItemGrid;
