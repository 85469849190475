import React from 'react';
import { useClasses } from 'common/utils/utils';
import { Tooltip } from '@mui/material';
import Info from '@mui/icons-material/Info';

const styles = {
  cardTooltip: {
    fontSize: 14,
  },
};

const InfoBubble = (props) => {
  const { infoMessage } = props;
  const classes = useClasses(styles);

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
        placement: 'bottom-end',
      }}
      enterDelay={100}
      leaveDelay={500}
      title={infoMessage}
      classes={{ tooltip: classes.cardTooltip }}
    >
      <Info />
    </Tooltip>
  );
};

export default InfoBubble;
