// import { createMuiTheme } from '@mmi/material';
// import ensoMaterialTheme from 'styles/enso-material-theme';

export const styles = (theme) => ({
  root: {
    width: 'auto',
    overflowX: 'auto',
    zIndex: 1,
  },
  table: {
    minWidth: 700,
  },
  // row: {
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.background.primary,
  //   },
  // },
  chipGreen: {
    backgroundColor: 'green',
  },
  reprocessPrimary: {
    color: '#B0C2C2',
  },
  reprocessSecondary: {
    color: '#E3E3E5',
  },
  customPatientsToolbar: {
    display: 'flex',
    order: 1,
    alignItems: 'center',
    flexFlow: 'row-reverse nowrap',
    justifyContent: 'space-between',
  },
  customTablePagination: {
    display: 'flex',
  },
  demoSticker: {
    backgroundColor: '#37474f',
    minWidth: 100,
    minHeight: 20,
  },
  main: {
    paddingTop: '.75rem',
    zIndex: 2,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1rem',
  },
  avatar: {
    marginRight: '1rem',
  },
  errorMessage: {
    color: '#f44336',
    paddingTop: '.75rem',
    fontWeight: 600,
    textAlign: 'center',
  },
  accordionRoot: {
    minHeight: 0,
  },
  accordionContent: {
    margin: 0,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const createTableTheme = () => {
  //   const defaultTheme = createMuiTheme(ensoMaterialTheme);
  return {
    ...styles,
    overrides: {
      MUIDataTableToolbar: {
        actions: {
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
      MuiTableRow: {
        root: {
          height: '42px',
        },
      },
      MuiTableCell: {
        root: {
          paddingTop: 1,
          paddingBottom: 1,
          paddingRight: 10,
          maxWidth: 250,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'pre',
        },
      },
      MUIDataTableBodyCell: {
        cellStackedSmall: {
          //   [defaultTheme.breakpoints.down('sm')]: {
          //     display: 'inline-flex',
          //     alignItems: 'center',
          //     backgroundColor: defaultTheme.palette.background.paper,
          //     fontSize: '16px',
          //     height: '33px',
          //     width: 'calc(80% - 80px)',
          //     whiteSpace: 'nowrap',
          //   },
        },
        stackedParent: {
          //   [defaultTheme.breakpoints.down('sm')]: {
          //     display: 'inline-flex',
          //     alignItems: 'center',
          //     fontSize: '16px',
          //     width: 'calc(80% - 80px)',
          //     whiteSpace: 'nowrap',
          //     height: '33px',
          //   },
        },
      },
    },
  };
};
