// Maximum number of bins on a histogram
export const MAX_NUM_BINS = 20;
export const MIN_NUM_BINS = 5;

export const getComplianceChartLabels = () => {
  let labels = [];
  for (let i = 0; i < 100; i++) {
    if (i % 5 === 0) {
      let label1 = i;
      let label2 = label1 + 5;
      labels.push(`${label1} - ${label2}%`);
    }
  }
  return labels;
};

/**
 * Function to get count from compliance bucket data passed to it for buckets of 5% increments and returns an array of count values
 * @param {array<object>} data an array of objects with key value pairs of Ids and count.
 * Key _id values represents the bucket increment.
 * Key count values repsents the count of patients in that bucket.
 * For example:
 *   [{_id: 0, count: 25}, {_id: 20, count: 12 }, {_id: 35, count: 50}, {_id: 45, count: 23}, {_id: 65, count: 11}].
 * Index 0, _id: 0 represents 0-5% bucket and has a count of 25 for that bucket.
 * For buckets that have a count of 0, data returns _id as 'Null' and count represents number of buckets that have count of 0
 * We filter out Null and sort data array by object _id to maintain buckets order by it's increment value.
 * @returns {array} an Array of numbers representing count number. Index of count number corresponds to the increment bucket.
 * Returns:
 * [25, 0, 0, 0, 12, 0, 0, 50, 0, 23, 0, 0, 0, 11, 0, 0, 0, 0, 0, 0, 0]
 * Index 0 is _id: 0, index 4 is _id: 20.
 */
export const getComplianceChartCount = (data) => {
  const array = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
  let result = [];
  array.map((ar) => {
    const index = data
      .map((e) => {
        return e._id;
      })
      .indexOf(ar);
    if (index === -1) {
      data.push({ _id: ar, count: 0 });
    }
    return index;
  });
  data
    .filter((x) => x._id !== 'Null')
    .sort((a, b) => {
      return a._id - b._id;
    })
    .map((k) => {
      result.push(k.count);
      return result;
    });
  return result;
};
