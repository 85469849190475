import React from 'react';
import Warning from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';

const WarningIcon = ({ size }) => {
  return (
    <div data-testid='warning-icon'>
      <Warning fontSize={size} />
    </div>
  );
};

WarningIcon.propTypes = {
  size: PropTypes.string,
};

export default WarningIcon;
