import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addTaskRequest, updateTaskRequest, setTaskView } from '../../store/actions';
import { TASKS_VIEW, taskTypeMapping } from 'common/constants/patient';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import {
  Button,
  Grid,
  Card,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
  Typography,
} from '@mui/material';

const SubmitButton = (props) => {
  const StyledButton = styled(Button)`
    padding: 10px 20px;
    margin-top: 30px;
  `;
  return (
    <StyledButton color={'primary'} variant={'contained'} {...props}>
      {props.label}
    </StyledButton>
  );
};

const TaskForm = (props) => {
  const {
    newTask,
    taskId,
    setTaskId,
    taskData,
    summary,
    patientId,
    clinicId,
    setTaskItemClick,
    setTaskSummary,
  } = props;
  const { handleSubmit, control } = useForm();
  const dispatch = useDispatch();

  const handleCancelTask = () => {
    setTaskItemClick(false);
    setTaskSummary('');
    dispatch(setTaskView({ taskView: TASKS_VIEW.listView }));
  };

  const onSubmit = (data) => {
    let steps = [];
    let dataList = Object.keys(data);
    if (dataList.length >= 2) {
      dataList.map((x, i) => {
        steps.push({ step: taskData[i].step.id, result: data[x] });
        return x;
      });
    } else {
      steps.push({ step: taskData[0].step.id, result: String(Object.values(data)) });
    }
    if (newTask) {
      let result = {
        patient: patientId,
        created: moment.utc(),
        due: moment.utc(),
        summary: summary,
        steps: steps,
        clinic: clinicId,
        type: taskTypeMapping[summary],
        source: 'user',
        completed: moment.utc(),
      };
      dispatch(setTaskView({ taskView: TASKS_VIEW.listView }));
      dispatch(addTaskRequest({ task: result, patientId: patientId }));
      setTaskItemClick(false);
    } else {
      let updates = {
        summary: summary,
        steps: steps,
        completed: moment.utc(),
      };
      dispatch(updateTaskRequest({ taskId, updates, patientId }));
      dispatch(setTaskView({ taskView: TASKS_VIEW.listView }));
      setTaskSummary('');
      setTaskId('');
      setTaskItemClick(false);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ overflow: 'scroll', height: '80vh', display: 'block', width: '100%' }}
    >
      <FormControl component='fieldset'>
        <Grid container xs={10} sx={{ width: '100%', margin: '0 auto ' }}>
          {taskData?.map((item, index) => {
            const step = item.step;
            const question = step.message;
            const stepNum = index + 1;
            const choices = step.choices;
            const inputType = step.input;
            return (
              <Card variant='outlined' sx={{ width: '100%', margin: '.25rem 0' }} key={index}>
                {inputType === 'text' && (
                  <Grid container sx={{ width: '100% ' }}>
                    <Grid xs={1} sx={{ padding: '1rem 0 0 1rem' }}>
                      <Typography variant='body2'>{stepNum}</Typography>
                    </Grid>
                    <Grid xs={11} sx={{ padding: '1rem 0 0 0' }}>
                      <Typography variant='body2'>{question}</Typography>
                    </Grid>
                    <Grid item xs={11} sx={{ padding: '0 0 1rem 2.5rem', width: '100%' }}>
                      <Controller
                        // rules={{ required: true }}
                        control={control}
                        name={question}
                        render={({ field }) => {
                          return <input {...field} style={{ width: '100%' }} />;
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                {inputType === 'boolean' && (
                  <Grid container sx={{ width: '100% ' }}>
                    <Grid xs={1} sx={{ padding: '1rem 0 0 1rem' }}>
                      {stepNum}
                    </Grid>
                    <Grid xs={11} sx={{ padding: '1rem 0 0 0' }}>
                      <Typography variant='body2'>{question}</Typography>
                    </Grid>
                    <Grid item xs={11} sx={{ padding: '0 0 1rem 2.5rem', width: '100%' }}>
                      <Controller
                        // rules={{ required: true }}
                        control={control}
                        name={question}
                        render={({ field }) => {
                          return (
                            <RadioGroup {...field}>
                              <FormControlLabel value='no' control={<Radio />} label='No' />
                              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                            </RadioGroup>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                {inputType === 'multiselect' && (
                  <Grid container sx={{ width: '100% ' }}>
                    <Grid xs={1} sx={{ padding: '1rem 0 0 1rem' }}>
                      {stepNum}
                    </Grid>
                    <Grid xs={11} sx={{ padding: '1rem 0 0 0' }}>
                      <Typography variant='body2'>{question}</Typography>
                    </Grid>
                    <Grid item xs={11} sx={{ padding: '0 0 2rem 15rem', width: '100%' }}>
                      <Controller
                        // rules={{ required: true }}
                        control={control}
                        name={question}
                        render={({ field }) => {
                          return (
                            <RadioGroup {...field}>
                              {choices.map((x, index) => {
                                return (
                                  <FormControlLabel
                                    key={index}
                                    value={x}
                                    control={<Radio />}
                                    label={x}
                                  />
                                );
                              })}
                            </RadioGroup>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Card>
            );
          })}
        </Grid>
        {/* <Grid>                                   
                {errors && <span style={{  color: 'red' }}>All fields are required.</span>}
            </Grid> */}
      </FormControl>
      <Grid container justifyContent='flex-end' xs={11}>
        <Grid item>
          <Button
            sx={{ padding: '10px 20px', marginRight: '1rem', marginTop: '30px' }}
            size='small'
            variant='text'
            onClick={() => handleCancelTask()}
            //   disabled={!isEditing}
          >
            Cancel
          </Button>
          <SubmitButton type='submit' label='Complete' />
        </Grid>
      </Grid>
    </form>
  );
};
export default TaskForm;
