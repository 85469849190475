import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';
import rootSaga from './rootSaga';
// const dev = process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();
const middleware = [thunk, sagaMiddleware];

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});
rootSaga.map((saga) => sagaMiddleware.run(saga));
export default store;
