import { call, put, takeLatest } from 'redux-saga/effects';
import { PatientActions } from './reducer';
import { formatPatientTaskQuery } from '../_utils';
import {
  // TODO: remove unused code
  // getPatient,
  getPatientTreatment,
  getTreatmentSessions,
  getPatientTasks,
  postPatientTask,
  patchPatientTask,
} from './api';
import {
  getPatientSuccess,
  getPatientFailure,
  getPatientTasksRequest,
  getPatientTasksRequestSuccess,
  getPatientTasksRequestFailure,
  addTaskSuccess,
  addTaskFailure,
  updateTaskSuccess,
  updateTaskFailure,
} from './actions';

export const PAGE_SIZE = 100;

function* getPatientRequest({ patientId, treatmentId, page = 0 }) {
  try {
    // const patientRes = yield call(getPatient, patientId)
    const treatmentsRes = yield call(getPatientTreatment, treatmentId);
    const sessionsRes = yield call(getTreatmentSessions, treatmentId);
    const tasksRes = yield call(getPatientTasks, patientId);
    const count = tasksRes?.data?.count;
    const hasMore = tasksRes?.data?.has_more;

    const patient = {
      treatment: { ...treatmentsRes.data.data },
      sessions: [...sessionsRes.data.data],
      tasks: tasksRes.data.data,
      count,
      hasMore,
      page,
    };

    yield put(
      getPatientSuccess({
        patient,
        loaded: true,
      })
    );
  } catch (e) {
    yield put(
      getPatientFailure({
        error: e.message,
        loaded: true,
      })
    );
  }
}

function* addTaskRequest({ task, patientId }) {
  try {
    const taskRes = yield call(postPatientTask, task);
    yield put(addTaskSuccess({ task: taskRes.data.data }));
    yield put(getPatientTasksRequest({ patientId }));
  } catch (e) {
    yield put(addTaskFailure({ error: e }));
  }
}

function* updateTaskRequest({ taskId, updates, patientId }) {
  try {
    yield call(patchPatientTask, { id: taskId, updates, patientId });
    yield put(updateTaskSuccess({ taskId, updates }));
    yield put(getPatientTasksRequest({ patientId }));
  } catch (e) {
    yield put(updateTaskFailure({ error: e }));
  }
}
function* getPatientTaskRequest({ patientId, page = 0, filters, limit = PAGE_SIZE }) {
  const skip = page * PAGE_SIZE;
  try {
    const formattedQuery = formatPatientTaskQuery(filters, limit, skip);
    const tasksRes = yield call(getPatientTasks, patientId, formattedQuery);
    const tasks = tasksRes.data.data;
    const count = tasksRes?.data?.count;
    const hasMore = tasksRes?.data?.has_more;
    yield put(
      getPatientTasksRequestSuccess({
        tasks,
        page,
        count,
        hasMore,
      })
    );
  } catch (e) {
    yield put(getPatientTasksRequestFailure({ error: e }));
  }
}

function* patientsSagas() {
  yield takeLatest(PatientActions.GET_PATIENT_REQUEST, getPatientRequest);
  yield takeLatest(PatientActions.ADD_TASK_REQUEST, addTaskRequest);
  yield takeLatest(PatientActions.UPDATE_TASK_REQUEST, updateTaskRequest);
  yield takeLatest(PatientActions.GET_PATIENT_TASKS_REQUEST, getPatientTaskRequest);
}

export default patientsSagas;
