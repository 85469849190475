const loginViewStyles = (theme) => ({
  container: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
  },
  formErrorText: {
    color: '#f44336',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  passwordWrapper: {
    width: '100%',
    textAlign: 'right',
  },
  logoWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  logoImage: {
    maxWidth: '849px',
    width: '80%',
    height: 'auto',
    marginBottom: '30px',
  },
  textFieldContainer: {
    alignSelf: 'center',
    textAlign: 'center',
    maxWidth: '500px',
    width: '90%',
    marginTop: '15px',
  },
  submitButton: {
    padding: '10px 20px',
    marginTop: '30px',
  },
});

export default loginViewStyles;
