import { UserActions } from './reducer';
import { makeActionCreator } from 'common/utils/utils';

export const fetchUserRequest = makeActionCreator(UserActions.FETCH_USER_REQUEST);
export const fetchUserSuccess = makeActionCreator(
  UserActions.FETCH_USER_SUCCESS,
  'clinic',
  'clinic_ref',
  'cohort',
  'email',
  'first',
  'id',
  'last',
  'roles',
  'tracking_enabled',
  'settings',
  'permissions'
);
export const fetchUserFailure = makeActionCreator(UserActions.FETCH_USER_FAILURE, 'error');

export const updateUserRequest = makeActionCreator(
  UserActions.UPDATE_USER_REQUEST,
  'userId',
  'tracking_enabled',
  'cohort',
  'clinic',
  'email',
  'id'
);
export const updateUserSuccess = makeActionCreator(UserActions.UPDATE_USER_SUCCESS);
export const updateUserFailure = makeActionCreator(UserActions.UPDATE_USER_FAILURE, 'error');

export const deleteUserSettingRequest = makeActionCreator(
  UserActions.DELETE_USER_SETTING_REQUEST,
  'userId',
  'setting'
);

export const updateUserSettingRequest = makeActionCreator(
  UserActions.UPDATE_USER_SETTING_REQUEST,
  'userId',
  'setting',
  'value'
);

export const updateUserSettingRequestNonReload = makeActionCreator(
  UserActions.UPDATE_USER_SETTING_REQUEST_NON_RELOAD,
  'userId',
  'setting',
  'value'
);

export const updateUserSettingSuccess = makeActionCreator(UserActions.UPDATE_USER_SETTING_SUCCESS);
export const updateUserSettingFailure = makeActionCreator(
  UserActions.UPDATE_USER_SETTING_FAILURE,
  'error'
);

export const simulateUserRequest = makeActionCreator(
  UserActions.SIMULATE_USER,
  'clinic',
  'role',
  'history'
);
export const resetSimulatedUser = makeActionCreator(UserActions.RESET_SIMULATED_USER, 'history');

export const resetUser = makeActionCreator(UserActions.RESET_USER);
