import {
  createReducer,
  updatingStore,
  successfulUpdate,
  failedUpdate,
  resetStore,
  clearErrors,
} from 'common/utils/utils';

const initialState = {
  isUpdating: false,
  isAuthenticated: false,
  isSuperUser: false,
  providerDomains: {},
  error: '',
};

export const AuthenticationActions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  SUPER_LOGIN_REQUEST: 'SUPER_LOGIN_REQUEST',
  SUPER_LOGIN_SUCCESS: 'SUPER_LOGIN_SUCCESS',
  SUPER_LOGIN_FAILURE: 'SUPER_LOGIN_FAILURE',

  REFRESH_PROVIDER_DOMAINS_REQUEST: 'REFRESH_PROVIDER_DOMAINS_REQUEST',
  REFRESH_PROVIDER_DOMAINS_SUCCESS: 'REFRESH_PROVIDER_DOMAINS_SUCCESS',
  REFRESH_PROVIDER_DOMAINS_FAILURE: 'REFRESH_PROVIDER_DOMAINS_FAILURE',

  REFRESH_AUTH_REQUEST: 'REFRESH_AUTH_REQUEST',
  REFRESH_AUTH_SUCCESS: 'REFRESH_AUTH_SUCCESS',
  REFRESH_AUTH_FAILURE: 'REFRESH_AUTH_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  CLEAR_ERRORS: 'CLEAR_ERRORS',
  INIT_AUTHENTICATION: 'INIT_AUTHENTICATION',
};

const authenticationReducer = createReducer(initialState, {
  [AuthenticationActions.LOGIN_REQUEST]: (state, actions) => updatingStore(state, actions),
  [AuthenticationActions.LOGIN_SUCCESS]: (state, actions) =>
    successfulUpdate(state, actions, ['isAuthenticated']),
  [AuthenticationActions.LOGIN_FAILURE]: (state, actions) => failedUpdate(state, actions),

  [AuthenticationActions.SUPER_LOGIN_SUCCESS]: (state, actions) =>
    successfulUpdate(state, actions, ['isSuperUser']),
  [AuthenticationActions.SUPER_LOGIN_FAILURE]: (state, actions) => failedUpdate(state, actions),

  [AuthenticationActions.REFRESH_PROVIDER_DOMAINS_SUCCESS]: (state, actions) => {
    return {
      ...state,
      error: '',
      providerDomains: actions.providerDomains,
    };
  },
  [AuthenticationActions.REFRESH_PROVIDER_DOMAINS_FAILURE]: (state, actions) => {
    return {
      ...state,
      error: actions.error,
    };
  },

  [AuthenticationActions.REFRESH_AUTH_REQUEST]: (state, actions) => updatingStore(state, actions),
  [AuthenticationActions.REFRESH_AUTH_SUCCESS]: (state, actions) =>
    successfulUpdate(state, actions, ['isAuthenticated']),
  [AuthenticationActions.REFRESH_AUTH_FAILURE]: (state, actions) => failedUpdate(state, actions),

  [AuthenticationActions.LOGOUT_REQUEST]: (state, actions) => updatingStore(state, actions),
  [AuthenticationActions.LOGOUT_SUCCESS]: (state, actions) =>
    resetStore(state, actions, initialState),
  [AuthenticationActions.LOGOUT_FAILURE]: (state, actions) => failedUpdate(state, actions),

  [AuthenticationActions.CLEAR_ERRORS]: (state, actions) => clearErrors(state, actions),
});

export default authenticationReducer;
