import authenticationSagas from '../../features/authentication/store/sagas';
import patientsSagas from 'features/patients/store/sagas';
import patientSagas from 'features/patient/store/sagas';
import userSagas from 'features/user/store/sagas';
import applicationSagas from 'features/application/store/sagas';
import filterSagas from 'features/filters/store/sagas';
import trendsSagas from 'features/trends/store/sagas';

export default [
  applicationSagas,
  authenticationSagas,
  patientsSagas,
  patientSagas,
  userSagas,
  filterSagas,
  trendsSagas,
];
