import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PatientsActions } from './reducer';
import { getPatientsSuccess, getPatientsFailure } from './actions';
import { getPatients, getTreatments } from './api';
// import { refreshReportPatient } from 'store/scoring/actions';
import filtersSelectors from 'features/filters/store/selectors';
// import userSelectors from '../user/selectors';
// import managementSelectors from '../management/selectors';
// import { updateFiltersRequest, updateSortOptionsRequest } from '../filters/actions';
import moment from 'moment';
// import { StudyManagementTab } from 'constants/tabs';
import { formatPatientsQuery } from '../_utils';

export const PAGE_SIZE = 20;

// TODO: remove unused code
// const replacePatient = (patients, nextPatient) => {
//   const oldPatIndex = patients.findIndex((pat) => pat.id === nextPatient.id);
//   if (oldPatIndex > -1) {
//     return [...patients.slice(0, oldPatIndex), nextPatient, ...patients.slice(oldPatIndex + 1)];
//   }
//   return patients;
// };

// function* updateOnePatient(nextPatient) {
//   const oldPatients = yield select(selectors.getPatients);
//   const oldSearchResults = yield select(selectors.getSearchResults);
//   return {
//     patients: replacePatient(oldPatients, nextPatient),
//     searchResults: replacePatient(oldSearchResults, nextPatient),
//   };
// }

export const createQueryWithRegex = (_query, search = '') => {
  const query = { ..._query }; // Avoid unnecessary mutation
  const fields = ['first_name', 'last_name'];

  // Remove escaped/unneeded charecters
  const parts = search.split(/\s+/);
  const partsRegex = parts.reduce((total, part) => {
    const escaped = part.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // eslint-disable-line no-useless-escape
    return total + escaped + '|';
  }, '');

  // Search input text without escaped/unneeded charecters
  const newSearch = partsRegex.slice(0, -1);

  const regexFields = fields.map((item) => {
    return { [item]: { $regex: newSearch, $options: 'i' } };
  });

  if (regexFields?.length > 0) {
    query.__raw__ = JSON.stringify({
      $or: regexFields,
    });
  }

  delete query['limit']; // Remove limit when searching via search input

  return query;
};

/**
 * Identify method to be called based on search and return array with method and args
 * @param {string} search
 * @param {object} query
 * @returns {Array<Function, string|object>}
 */
export const identifyCallback = (search, query) => {
  if (!search) {
    return [getTreatments, query];
  }

  // Check if search is an Enso ID
  if (/^[a-f\d]{24}$/i.test(search)) {
    return [getTreatments, search];
  }

  return [getPatients, createQueryWithRegex(query, search)];
};

// Person can have multiple treatements, so map each treatment and session to a person
export const mapPatientsResponse = (patientsRes, treatmentsRes, sessionsRes = []) => {
  // Empty response
  if (!patientsRes?.data || !Object.keys(patientsRes?.data?.data).length) {
    return [];
  }

  // create patients object that has treatment and sessions
  const patients = [];
  patientsRes?.data?.data?.forEach((pat) => {
    treatmentsRes?.data?.data
      ?.filter((treatment) => treatment.person === pat.id)
      .forEach((treatment) => {
        // pull in al treatment sessions aswell?
        // let sessions = sessionsRes?.data?.data.filter(session => treatment.id === session.treatment)

        // TODO dont display inactive treatments ?
        // if (!treatment.inactive){
        patients.push({
          ...pat,
          // treatment: {...treatment,sessions: [...sessions]}
          treatment: { ...treatment },
        });
        // }
      });
  });

  return patients;

  // }

  // // 'getPatient' (singular) was called
  // return [å
  //   {
  //     ...patients.data.data,
  //     // ...processingInfo,
  //   },
  // ];
};

function* getPatientsRequest({ page = 0, limit = PAGE_SIZE }) {
  let patientData = [];

  try {
    // const searchInput = yield select(filtersSelectors.getSearchInput);
    const filters = yield select(filtersSelectors.getQueryableFilters);
    const skip = page * PAGE_SIZE;
    const formattedQuery = formatPatientsQuery(filters, limit, skip);
    const treatmentsRes = yield call(...identifyCallback('', formattedQuery));
    // const sessionsRes = yield call(getTreatmentSessions, {})
    const count = treatmentsRes?.data?.count;
    const hasMore = treatmentsRes?.data?.has_more;
    // patientData = mapPatientsResponse(patientsRes, treatmentsRes);onChangePage
    yield put(
      getPatientsSuccess({
        page,
        patients: [...treatmentsRes.data.data],
        count,
        hasMore,
        lastUpdated: moment(),
        // search: {
        //   isSearching: false,
        //   patients: patientsRes?.data?.data,
        //   search: '',
        // },
      })
    );
  } catch (e) {
    yield put(
      getPatientsFailure({
        error: e.message,
        patients: patientData,
      })
    );
  }
}

function* patientsSagas() {
  yield takeLatest(PatientsActions.GET_PATIENTS_REQUEST, getPatientsRequest);
}

export default patientsSagas;
