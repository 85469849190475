import { createSelector } from 'reselect';

const getApplicationState = (state) => state?.application;

const getIsUpdating = createSelector(
  getApplicationState,
  (applicationState) => applicationState?.isUpdating
);

const getAbout = createSelector(getApplicationState, (applicationState) => applicationState?.about);

const getStatuses = createSelector(
  getApplicationState,
  (applicationState) => applicationState?.statuses
);

const getSoftwares = createSelector(
  getApplicationState,
  (applicationState) => applicationState?.softwares
);

const getIsDemoMode = createSelector(
  getApplicationState,
  (applicationState) => applicationState?.isDemoMode
);

const getError = createSelector(getApplicationState, (applicationState) => applicationState?.error);

export default {
  getApplicationState,
  getIsUpdating,
  getAbout,
  getStatuses,
  getSoftwares,
  getIsDemoMode,
  getError,
};
