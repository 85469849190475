import React from 'react';
import Email from '@mui/icons-material/Email';
import PropTypes from 'prop-types';

const EmailIcon = ({ size }) => {
  return (
    <div data-testid='email-icon'>
      <Email fontSize={size} />
    </div>
  );
};

EmailIcon.propTypes = {
  size: PropTypes.string,
};

export default EmailIcon;
