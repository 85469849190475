import { Card, List, ListItemText, Typography, Grid, ListItem, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment-timezone';

const IconItem = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.35)',
  ...theme.typography.body2,
  padding: '6px 11px',
  textAlign: 'center',
  color: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.35)' : '#fff',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  marginTop: '8px',
  marginRight: '27px',
}));

const TaskItem = ({ taskSteps, message, type, date }) => {
  let formattedDate = moment(date).format('MM/DD/YY');
  return (
    <Grid
      container
      xs={10}
      justifyContent='center'
      alignItems='center'
      sx={{ width: '100%', margin: '0 auto' }}
    >
      <Card variant='outlined' sx={{ width: '100%', maxHeight: '80vh', margin: '.25rem 0' }}>
        <Grid container xs={10} sx={{ height: '100%', width: '100%', margin: '1rem auto 0' }}>
          <Grid xs={9} sx={{ paddingLeft: '.75rem' }}>
            <Typography variant='h6' sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              {`${message}`}
            </Typography>
          </Grid>
          <Grid xs={2} sx={{ paddingLeft: '.75rem' }}>
            <Typography variant='h6' sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              {formattedDate}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={10} sx={{ margin: '0 auto', width: '100%' }}>
          <Divider component='div' />
        </Grid>
        <Grid container xs={10} sx={{ margin: '0 auto' }}>
          <div style={{ overflow: 'scroll', height: '100%', display: 'block', width: '100%' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {taskSteps?.map((task, index) => {
                const stepNum = index + 1;
                const question = task.step.message;
                const result = task.result;
                return (
                  <>
                    <ListItem alignItems='flex-start' key={index}>
                      <IconItem>{stepNum}</IconItem>
                      <ListItemText
                        // primary={question}
                        disableTypography
                        primary={
                          <Typography type='body2' sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                            {question}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography
                              sx={{ display: 'inline', color: 'rgba(0, 0, 0, 0.6)' }}
                              component='span'
                              variant='body2'
                            >
                              {result}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    {index !== taskSteps.length - 1 && <Divider variant='inset' component='li' />}
                  </>
                );
              })}
            </List>
          </div>
        </Grid>
      </Card>
    </Grid>
  );
};

export default TaskItem;
