import { createReducer } from 'common/utils/utils';

const initialState = {
  complianceBuckets: {},
};

export const TrendsActions = {
  GET_TRENDS_REQUEST: 'GET_TRENDS_REQUEST',
  GET_TRENDS_SUCCESS: 'GET_TRENDS_SUCCESS',
  GET_TRENDS_FAILURE: 'GET_TRENDS_FAILURE',
};

const TrendsReducer = createReducer(initialState, {
  [TrendsActions.GET_TRENDS_REQUEST]: (state) => ({
    ...state,
    ...initialState,
    loaded: false,
  }),
  [TrendsActions.GET_TRENDS_SUCCESS]: (state, actions) => {
    return {
      ...state,
      complianceBuckets: actions.complianceBuckets,
      loaded: true,
    };
  },
  [TrendsActions.GET_TRENDS_FAILURE]: (state, actions) => ({
    ...state,
    error: actions.error,
    loaded: true,
  }),
});

export default TrendsReducer;
