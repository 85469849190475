import { Box, Typography } from '@mui/material';
import { useState, useEffect, createRef } from 'react';
import { getChartConfig } from './chartConfig';
import { Chart } from 'chart.js';

const ComplianceSpark = ({ index, treatment }) => {
  const chartRef = createRef();
  const [chart, setChart] = useState(null);
  const [compliance, setCompliance] = useState();
  const [compliant, setCompliant] = useState();

  // destroying and recreating chart on every data change is slower, but it also is need so we dont have old chart data linger on sort/page change
  useEffect(() => {
    // const chartDataReversed = chartData?.reverse()
    const chartData = treatment?.compliance ?? [];
    setCompliance(treatment?.current_compliance);
    setCompliant(treatment?.current_compliance >= 70 ? true : false);
    const myChartRef = chartRef.current.getContext('2d');
    const config = getChartConfig({ chartData, compliant });
    const newChart = new Chart(myChartRef, config);
    setChart(newChart);

    return () => newChart.destroy();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCompliance(treatment?.current_compliance);
    setCompliant(treatment?.current_compliance >= 70 ? true : false);
    if (chart) {
      const chartData = treatment?.compliance ?? [];
      chart.data.datasets[0].data = chartData;
      chart.update();
    }
  }, [treatment]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='left'>
      <Box className='chart-container' sx={{ position: 'relative', height: '5vh', width: '20vh' }}>
        <canvas id={`chart-${index}`} ref={chartRef}></canvas>
      </Box>
      {compliance !== undefined && (
        <Box display='flex' flexDirection='column'>
          <Box
            // component={'span'}
            display='flex'
            sx={{
              borderRadius: '.5rem',
              background: compliant ? 'green' : 'red',
              margin: '.2rem',
              width: '3rem',
              height: '1.2rem',
              textAlign: 'center',
              color: 'white',
            }}
          >
            <Typography variant='caption' sx={{ margin: 'auto' }}>
              {compliance}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ComplianceSpark;
