import {
  put,
  all,
  takeLatest,
  // TODO: remove used code
  // select
} from 'redux-saga/effects';
import { FiltersActions } from './reducer';
import { resetPatientsRequest, getPatientsRequest } from 'features/patients/store/actions';
// import { fetchInsightsRequest } from '../insights/actions';
// import patientsSelectors from 'features/patients/store//selectors';

function* applyFilters(actions) {
  // yield put(resetPatientsRequest());
  yield all([put(getPatientsRequest({ page: 0 }))]);
}

function* resetFilters(actions) {
  // const tab = yield select(patientsSelectors.getTab);
  yield put(resetPatientsRequest());
  // yield all([put(setPatientsTab({ tab }))]);
}

function* applicationSagas() {
  yield takeLatest(
    [
      // FiltersActions.UPDATE_DATES,
      FiltersActions.UPDATE_SORT_OPTIONS,
      // FiltersActions.UPDATE_FILTERS,
      FiltersActions.CLEAR_SORT_OPTIONS,
      // FiltersActions.CLEAR_SEARCH_INPUT,
    ],
    applyFilters
  );
  yield takeLatest([FiltersActions.RESET_FILTER_PARAMS], resetFilters);
}

export default applicationSagas;
