import { TrendsActions } from './reducer';
import { makeActionCreator } from 'common/utils/utils';

export const getTrendsRequest = makeActionCreator(TrendsActions.GET_TRENDS_REQUEST);
export const getTrendsSuccess = makeActionCreator(
  TrendsActions.GET_TRENDS_SUCCESS,
  'complianceBuckets',
  'loaded'
);

export const getTrendsFailure = makeActionCreator(
  TrendsActions.GET_TRENDS_FAILURE,
  'error',
  'loaded'
);
