// import path from 'path';

export const rootPath = '/';

export const loginPath = '/login';

// export const buildInsightsPath = () => {
//   return path.resolve(rootPath, `insights`);
// };

// export const buildInstallPath = () => {
//   return path.resolve(rootPath, `install`);
// };

// export const buildClinicsPath = () => {
//   return path.resolve(rootPath, `clinic`);
// };

// export const buildPatientsPath = () => {
//   return path.resolve(rootPath, `patients`);
// };

// export const buildPatientPath = (patientId) => {
//   return path.resolve(buildPatientsPath(), `${patientId}`);
// };

// export const buildConfigurationsPath = (clinicId) => {
//   return path.resolve(rootPath, `configurations`);
// };

// export const buildConfigurationPath = (clinicId) => {
//   return path.resolve(rootPath, `configurations/${clinicId}`);
// };

// export const buildComputerPath = (clinicId, computerId) => {
//   return path.resolve(buildConfigurationPath(clinicId), `computers/${computerId}`);
// };

// export const buildDirectoryPath = (clinicId, computerId, directoryId) => {
//   return path.resolve(buildComputerPath(clinicId, computerId), `directories?path=${directoryId}`);
// };

// export const buildDeveloperPath = (tab) => {
//   return path.resolve(rootPath, tab ? `developer/${tab}` : 'developer');
// };
