import { Card, ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import IconByType from 'common/components/IconByType/IconByType';

const TaskListItem = ({ message, type, date, color, onClick }) => {
  return (
    <Card
      variant='outlined'
      sx={{ borderColor: color, width: '100%', margin: '.25rem 0' }}
      onClick={onClick}
    >
      <ListItem secondaryAction={<Typography variant='body2'>{date}</Typography>}>
        <ListItemIcon>
          <IconByType type={type} />
        </ListItemIcon>
        <ListItemText primary={<Typography variant='body2'>{message}</Typography>} />
      </ListItem>
    </Card>
  );
};

export default TaskListItem;
