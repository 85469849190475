import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment';
import moment from 'moment';

Chart.register(annotationPlugin);

export const getChartConfig = ({ chartData, backgroundColor, annotations = {} }) => ({
  type: 'bar',
  data: {
    labels: [...Array(chartData[0]?.length).keys()]
      .reverse()
      .map((i) => moment().utc().subtract(i, 'd').format('M/D')),
    datasets: chartData.map((session, i) => ({
      data: session,
      ...barConfig,
      backgroundColor: backgroundColor[i],
    })),
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    normalized: true,
    // parsing: false,
    animation: false,
    plugins: {
      annotation: {
        whatever: (context, opts) => {
          console.log(context, opts);
        },
        annotations: {
          line: {
            type: 'line',
            yMin: 24,
            yMax: 24,
            borderColor: 'gray',
            borderWidth: 1,
            borderDash: [4, 4],
          },
          ...annotations,
        },
      },
      tooltip: {
        callbacks: {
          label: ({ chart, datasetIndex, dataIndex }) => {
            return datasetIndex;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        stacked: true,
        // barThickness: 20
        // ticks:{
        //     stepSize: 1,
        //     // autoSkip: false,
        // },
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          display: false,
          font: {
            size: 10,
          },
        },
      },
      y: {
        afterFit: function (scaleInstance) {
          scaleInstance.width = 40;
        },
        // beginAtZero: true,
        display: true,
        // stacked: true,
        // type: 'time',
        // time: {
        //     unit: 'day'
        // }
        min: 12,
        max: 36,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          // reverse: true,
          font: {
            size: 10,
          },
          stepSize: 4,
          autoSkip: false,
          callback: function (label, index, labels) {
            let l = label;
            if (l > 24) l -= 24;
            return moment(parseFloat(l), ['HH:mm']).format('ha');
          },
        },
      },
    },
  },
});

// const nonUsageAnnotations = {
//     id: 'nonUsageBar',
//     beforeDatasetsDraw(chart, args, pluginOptions) {
//         const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { x, y }, noData } = chart;
//         // ctx.save()
//         ctx.strokeStyle = "#B60505"
//         console.log('width,height', width, height)
//         noData.forEach((bool, i) => {
//             console.log('x.getPixelforvalue', x.getPixelForValue(i))
//             if (bool) {
//                 annotations['box' + i] = {
//                     drawTime: 'afterDraw',
//                     type: 'box',
//                     xMin: x.getPixelForValue(i),
//                     xMax: x.getPixelForValue(i) + 10,
//                     yMin: height,
//                     yMax: height + 10,
//                     backgroundColor: 'rgba(255, 99, 132, 0.5)'
//                 }
//             }
//         })
//     }
// }

const barConfig = {
  //     barThickness: 12,
  //     barPercentage: 1,
  //     categoryPercentage: 1
  // borderWidth: 1,
};
