import React from 'react';
import Message from '@mui/icons-material/Message';
import PropTypes from 'prop-types';

const TextIcon = ({ size }) => {
  return (
    <div data-testid='note-icon'>
      <Message fontSize={size} />
    </div>
  );
};

TextIcon.propTypes = {
  size: PropTypes.string,
};

export default TextIcon;
