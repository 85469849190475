import { PatientsActions } from './reducer';
import { makeActionCreator } from 'common/utils/utils';

export const setPatientsTab = makeActionCreator(PatientsActions.SET_PATIENTS_TAB, 'tab');

export const setPatientsAvailableTabs = makeActionCreator(
  PatientsActions.SET_PATIENTS_AVAILABLE_TABS,
  'availableTabs'
);

export const getPatientsRequest = makeActionCreator(PatientsActions.GET_PATIENTS_REQUEST, 'page');

export const getPatientsSuccess = makeActionCreator(
  PatientsActions.SEARCH_PATIENTS_SUCCESS,
  'page',
  'patients',
  'count',
  'hasMore',
  'lastUpdated',
  'search'
);
export const getPatientsFailure = makeActionCreator(
  PatientsActions.SEARCH_PATIENTS_FAILURE,
  'error'
);

export const resetPatientsRequest = makeActionCreator(
  PatientsActions.RESET_PATIENTS_REQUEST,
  'except'
);

// export const processPatientsSuccess = makeActionCreator(
//   PatientsActions.PROCESS_PATIENTS_SUCCESS,
//   'patients',
//   'searchResults'
// );
// export const processPatientsFailure = makeActionCreator(
//   PatientsActions.PROCESS_PATIENTS_FAILURE,
//   'error'
// );
