import { call, put, takeLatest } from 'redux-saga/effects';
import { UserActions } from './reducer';
import {
  fetchUserFailure,
  fetchUserSuccess,
  updateUserSettingSuccess,
  updateUserSettingFailure,
} from './actions';
import { getCurrentUser, simulateClinicRole, postResetSuperRole, putUserSetting } from './api';
import { initFetch } from 'features/application/store/sagas';

// TODO pull in users feature if needed
// import { updateUser as updateUserApi } from 'featurs/users/api';

function* fetchUserInfo() {
  const response = yield call(getCurrentUser);
  const user = response.data.data;
  yield put(fetchUserSuccess(user));
  return user;
}

function* fetchUser(actions) {
  try {
    const response = yield call(getCurrentUser);
    const user = response.data.data;
    yield call(initFetch, { user });
    yield put(fetchUserSuccess(user));
  } catch (e) {
    yield put(fetchUserFailure({ error: e.message }));
  }
}

function* simulateUser(actions) {
  const { clinic, role } = actions;

  try {
    yield call(simulateClinicRole, clinic, role);
  } finally {
    window.location.reload();
  }
}

function* resetSimulatedUser(actions) {
  try {
    yield call(postResetSuperRole);
  } finally {
    window.location.reload();
  }
}

// function* updateUser(actions) {
//   const { userId, type, ...rest } = actions;

//   try {
//     yield call(updateUserApi, userId, { ...rest });
//     yield put(updateUserSuccess());
//     yield* fetchUserInfo();
//   } catch (e) {
//     yield put(updateUserFailure({ error: e.message }));
//   }
// }

function* updateUserSetting(actions) {
  const { userId, setting, value } = actions;
  try {
    yield call(putUserSetting, userId, setting, value);
    yield put(updateUserSettingSuccess());
    yield* fetchUserInfo();
  } catch (e) {
    yield put(updateUserSettingFailure({ error: e.message }));
  }
}

function* updateUserSettingNonReload(actions) {
  const { userId, setting, value } = actions;
  try {
    yield call(putUserSetting, userId, setting, value);
    yield* fetchUserInfo();
  } catch (e) {
    yield put(updateUserSettingFailure({ error: e.message }));
  }
}

function* userSagas() {
  yield takeLatest(UserActions.FETCH_USER_REQUEST, fetchUser);
  // yield takeLatest(UserActions.UPDATE_USER_REQUEST, updateUser);
  yield takeLatest(UserActions.UPDATE_USER_SETTING_REQUEST, updateUserSetting);
  yield takeLatest(UserActions.UPDATE_USER_SETTING_REQUEST_NON_RELOAD, updateUserSettingNonReload);
  yield takeLatest(UserActions.SIMULATE_USER, simulateUser);
  yield takeLatest(UserActions.RESET_SIMULATED_USER, resetSimulatedUser);
}

export default userSagas;
