import client from 'common/api/client';

export const getClinicUsers = (clinicName) => {
  const endpoint = '/user';
  return client.get(endpoint, {
    params: {
      clinic: clinicName,
      active: true,
    },
  });
};

export const simulateClinicRole = (clinic, role) => {
  const endpoint = '/dashboard/simulate_clinic_user';
  return client.post(endpoint, { clinic, role });
};

export const postResetSuperRole = () => {
  const endpoint = '/dashboard/reset_super_role';
  return client.get(endpoint);
};

export const getCurrentUser = () => {
  const endpoint = '/user/current';
  return client.get(endpoint);
};

export const getUserSetting = (userId) => {
  const endpoint = `/user/${userId}/setting`;
  return client.get(endpoint);
};

export const putUserSetting = (userId, setting, value) => {
  const endpoint = `/user/${userId}/setting/${setting}`;
  return client.put(endpoint, { value });
};

export const deleteUserSetting = (userId, setting) => {
  const endpoint = `/user/${userId}/setting/${setting}`;
  return client.delete(endpoint);
};
