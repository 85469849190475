import { wrapItInAMoment } from 'common/utils/_moment';
import moment from 'moment';

export const formatPatientsQuery = (filters, limit, skip) => {
  return {
    // status__ne: 8,
    // // Use ne for previous patients that did not have the historical field at all (undefined)
    // settings__historical__ne: true,
    ...filters,
    limit,
    skip,
  };
};

export const getDaysSinceTreatmentStart = (start) => {
  const startDate = wrapItInAMoment(start).local();
  const now = moment.now();
  return Math.abs(startDate.diff(now, 'days')) + 1;
};

export const getHourAsBase10 = (timestamp) => {
  return parseFloat(
    moment.duration(wrapItInAMoment(timestamp).format('HH:mm')).asHours().toFixed(2)
  );
};

// TODO move over color value to theme
export const getBarColor = (barLength) => {
  // if (barLength >=4) return  'rgba(38, 166, 91, 1)'
  // if (barLength < 4) return  'rgba(255, 0, 0,1)'
  if (barLength >= 4) return 'green';
  if (barLength < 4) return 'red';
};
