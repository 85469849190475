import { Box } from '@mui/material';
import { useEffect, createRef } from 'react';
import { getChartConfig } from './chartConfig';
import { Chart } from 'chart.js';
import { wrapItInAMoment } from 'common/utils/_moment';
import { getHourAsBase10 } from 'features/patients/_utils';
import moment from 'moment';

const ActivityChart = ({ sessions, tasks }) => {
  const chartRef = createRef();

  useEffect(() => {
    // sort by latest,
    let data = tasks.sort(
      (a, b) =>
        wrapItInAMoment(b?.completed).format('YYYYMMDD') -
        wrapItInAMoment(a?.completed).format('YYYYMMDD')
    );

    // // filter out all sessions but last month
    const monthAgo = moment().utc().subtract(30, 'd').format('YYYYMMDD');
    data = data.filter(
      (activity) =>
        activity?.completed && wrapItInAMoment(activity?.completed).format('YYYYMMDD') > monthAgo
    );

    const minMax = [];
    let mappedActivities = {};
    data.forEach((activity) => {
      let currDate = wrapItInAMoment(activity?.completed).format('YYYYMMDD');
      if (!mappedActivities.hasOwnProperty(currDate))
        mappedActivities[wrapItInAMoment(activity?.completed).format('YYYYMMDD')] = [];
      mappedActivities[currDate].push(activity);
      minMax.push(getHourAsBase10(activity?.completed));
    });

    const chartData = [];
    const metaData = [];
    Array(30)
      .fill(0)
      .forEach((_, i) => {
        let currentDay = wrapItInAMoment(moment().utc()).subtract(i, 'd').format('YYYYMMDD');
        if (mappedActivities.hasOwnProperty(currentDay)) {
          mappedActivities[currentDay].forEach((activity, j) => {
            if (!chartData[j]) chartData[j] = Array(30).fill(undefined);
            if (!metaData[j]) metaData[j] = Array(30).fill(undefined);
            let point = getHourAsBase10(activity?.completed);
            chartData[j][i] = [point, point + 1];
            metaData[j][i] = activity;
          });
        }
      });

    const myChartRef = chartRef.current.getContext('2d');
    const config = getChartConfig({ chartData });
    const newChart = new Chart(myChartRef, config);
    newChart.metaData = metaData;
    newChart.update();

    return () => newChart.destroy();
  }, [tasks]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className='chart-container' sx={{ position: 'relative', height: '16vh', width: '100%' }}>
      <canvas id={`therapy-usage`} ref={chartRef}></canvas>
    </Box>
  );
};

export default ActivityChart;
