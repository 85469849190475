export const lineColorPlugin = {
  id: 'lineColorPlugin',
  afterLayout: (chart) => {
    let ctx = chart.ctx;
    ctx.save();
    const threshold = 70.0; // TODO pull this in as a chart config value
    let yAxis = chart.scales.y;
    let yThreshold = yAxis.getPixelForValue(threshold);
    let gradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom);
    gradient.addColorStop(0, 'green');
    let offset = (1 / yAxis.bottom) * yThreshold;
    gradient.addColorStop(offset, 'green');
    gradient.addColorStop(offset, 'red');
    gradient.addColorStop(1, 'red');
    chart.data.datasets[0].borderColor = gradient;
    ctx.restore();
  },
};
