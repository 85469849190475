import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

export const wrapItInAMoment = (utcTime) => moment.utc(utcTime);

export const timeZone = moment.tz.guess();
export const tzMoment = moment.tz.setDefault(timeZone);

const ADJUST_TYPE = {
  Add: 'add',
  Sub: 'subtract',
};

export const formatDateTime = (dateTime, options = {}) => {
  const { adjust = {}, format = 'lll', timezone = false } = options;
  const tzGuess = moment.tz.guess();
  const utcTime = moment.utc(dateTime);

  if (!utcTime.isValid) {
    return 'None'; //TODO: Change this to return undefined/null
  }

  if (!isEmpty(adjust)) {
    const { type = ADJUST_TYPE.Add, time = 0, unit = 'seconds' } = adjust;

    if (type === ADJUST_TYPE.Add) {
      utcTime.add(time, unit);
    } else {
      utcTime.subtract(time, unit);
    }
  }
  let formatted = moment.utc(utcTime);
  if (timezone) {
    formatted = formatted.tz(tzGuess);
  }
  return formatted.format(format);
};

export const formatTimeDuration = (time, unit = 'seconds') => {
  const duration = moment.duration(time, unit);
  const format = !!duration.hours() ? 'h:mm:ss' : 'm:ss';

  return moment.utc(duration.asMilliseconds()).format(format);
};

export const formatDate = (dateTime, empty = '---') => {
  if (dateTime) {
    return moment(dateTime).format('l');
  } else {
    return empty;
  }
};
