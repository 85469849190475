/**
 * A constant of task types displayed to the user to create a task
 */
export const TASKS_ACTION_ICON_TYPES = {
  call: 'call',
  email: 'email',
  text: 'text',
  note: 'note',
};

/**
 * A constant of different views supported for tasks
 */
export const TASKS_VIEW = {
  formView: 'formView',
  listView: 'listView',
  itemView: 'itemView',
};

/**
 * Mapping of task types
 */

export const taskTypeMapping = {
  'Log a call': 'call',
  'Log a email': 'email',
  'Log a text': 'text',
  'Log a note': 'note',
};
