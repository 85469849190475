import 'chartjs-adapter-moment';
import { getComplianceChartLabels, getComplianceChartCount } from '../_utils';

export const getChartConfig = ({ chartData }) => ({
  type: 'bar',
  data: {
    labels: getComplianceChartLabels(),
    datasets: [
      {
        label: 'Patient Count',
        data: getComplianceChartCount(chartData).slice(0, 20),
        backgroundColor: [
          'rgba(0, 142, 48, 0.2)',
          'rgba(255, 0, 0, 0.2)',
          'rgba(221, 227, 0, 0.2)',
          'rgba(28, 34, 211, 0.2)',
          'rgba(111, 63, 0, 0.2)',
        ],
        borderColor: [
          'rgba(0, 142, 48)',
          'rgba(255, 0, 0)',
          'rgba(221, 227, 0)',
          'rgba(28, 34, 211)',
          'rgba(111, 63, 0)',
        ],
        borderWidth: 1,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Patient Count',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Compliance Percentage',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  },
});
