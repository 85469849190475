import React from 'react';
import { Typography } from '@mui/material';
import cx from 'classnames';
import { useClasses } from 'common/utils/utils';

const InfoField = ({
  label,
  value,
  labelComponent,
  split = false,
  typoProps = {},
  divProps = {},
}) => {
  const classes = useClasses({
    fieldLabel: {
      fontWeight: 550,
      fontSize: 12,
    },
    fieldValue: {
      fontWeight: 200,
      fontSize: 12,
    },
  });

  const textLabel = !!split ? label : `${label}: `;

  return (
    <div className={divProps.className}>
      <Typography
        variant='body1'
        component={!!split ? 'div' : 'span'}
        className={cx(classes.fieldLabel, typoProps.className)}
      >
        {!!label ? textLabel : labelComponent}
      </Typography>
      <Typography
        variant='body1'
        component={!!split ? 'div' : 'span'}
        className={cx(classes.fieldValue, typoProps.className)}
      >
        {value}
      </Typography>
    </div>
  );
};

export default InfoField;
