import React from 'react';

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.log('Displaying fallback UI');
  }

  render() {
    if (this.state.hasError) {
      return (
        <h1>
          Uh oh! An unknown error occurred. Please report this bug to EnsoData and reload the page.
        </h1>
      );
    } else {
      return this.props.children;
    }
  }
}

export default AppErrorBoundary;
