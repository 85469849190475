import {
  // TODO: remove unused code
  // initialState,
  FiltersActions,
} from './reducer';
import { makeActionCreator } from 'common/utils/utils';

// export const resetFilterParmetersRequest = makeActionCreator(FiltersActions.RESET_FILTER_PARAMS);

// export const updateFiltersRequest = makeActionCreator(
//   FiltersActions.UPDATE_FILTERS,
//   ...Object.keys(initialState.params)
// );

// export const updateDatesRequest = makeActionCreator(
//   FiltersActions.UPDATE_DATES,
//   'dateStart',
//   'dateEnd'
// );

export const updateSortOptionsRequest = makeActionCreator(
  FiltersActions.UPDATE_SORT_OPTIONS,
  'sortOptions'
);

export const initSortOptionsRequest = makeActionCreator(
  FiltersActions.INIT_SORT_OPTIONS,
  'sortOptions'
);

export const clearSortOptionsRequest = makeActionCreator(FiltersActions.CLEAR_SORT_OPTIONS);

// export const setRequiredParams = makeActionCreator(
//   FiltersActions.SET_REQUIRED_PARAMS,
//   'requiredParams'
// );

// export const updateSearchInput = makeActionCreator(
//   FiltersActions.UPDATE_SEARCH_INPUT,
//   'searchInput'
// );

// export const clearSearchInput = makeActionCreator(FiltersActions.CLEAR_SEARCH_INPUT);

// export const updateSearchFields = makeActionCreator(
//   FiltersActions.UPDATE_SEARCH_FIELDS,
//   'searchFields'
// );

// export const clearSearchFields = makeActionCreator(FiltersActions.CLEAR_SEARCH_FIELDS);
