import React from 'react';
import { Button, IconButton, CircularProgress } from '@mui/material';

const SubmitButton = ({
  children,
  component,
  icon,
  loading = false,
  size = '1.75rem',
  ...other
}) => {
  const ButtonComponent = !!component || (!!icon ? IconButton : Button);

  return (
    <ButtonComponent disabled={loading} {...other}>
      {loading ? <CircularProgress color={'secondary'} size={size} /> : children}
    </ButtonComponent>
  );
};

export default SubmitButton;
