import { useState, useEffect, useRef } from 'react';

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useWhyDidYouUpdate = (name, props) => {
  const previousProps = useRef();

  useEffect(() => {
    if (previousProps.current) {
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      const changesObj = {};
      allKeys.forEach((key) => {
        if (previousProps.current[key] !== props[key]) {
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      if (Object.keys(changesObj).length) {
        console.log('[why-did-you-update]', name, changesObj);
      }
    }

    previousProps.current = props;
  });
};

export const useEventListener = (eventName, handler, element = window, capture = false) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const elementIsRef = element.hasOwnProperty('current');
    const currentElement = elementIsRef ? element.current : element;
    const isSupported = !!currentElement && !!currentElement.addEventListener;
    if (!isSupported) return;

    const eventListener = (event) => savedHandler.current(event);
    currentElement.addEventListener(eventName, eventListener, capture);

    return () => {
      if (!!currentElement) {
        currentElement.removeEventListener(eventName, eventListener);
      }
    };
  }, [eventName, element, capture]);
};

//
// TODO: REMOVE DUPLICATE FUNCS
//

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useWindowResize = (callback, delay) => {
  const [width, setWidth] = useState();
  const currentWidth = useDebounce(width, delay);

  useEventListener('resize', () => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    if (!!currentWidth) {
      callback(currentWidth);
    }
  }, [currentWidth, callback]);
};

export const useCheckApiAvailable = (callback, pingApi) => {
  const [apiAlive, setApiAlive] = useState(true);
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleNetworkChange = () => {
      if (!window.navigator.onLine) {
        setIsOnline(false);
      } else {
        setIsOnline(true);
      }
    };
    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    return () => {
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useInterval(
    async () => {
      try {
        const { status } = await pingApi();
        setApiAlive(status === 200);
      } catch (e) {
        setApiAlive(false);
      }
    },
    isOnline ? 15000 : null
  );

  useEffect(() => {
    if (apiAlive && isOnline) {
      callback(true);
    } else {
      callback(false);
    }
  }, [callback, isOnline, apiAlive]);
};
