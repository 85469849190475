import client from '../../../common/api/client';

export const getAbout = () => {
  const endpoint = '/release/about';
  return client.get(endpoint);
};

export const getStatuses = () => {
  const endpoint = '/release/status';
  return client.get(endpoint);
};

export const getSoftwares = () => {
  const endpoint = '/release/software';
  return client.get(endpoint);
};

export const getPing = () => {
  const endpoint = '/ping';
  return client.get(endpoint);
};
