import { combineReducers } from 'redux';
import authentication from 'features/authentication/store/reducer';
import application from 'features/application/store/reducer';
import user from 'features/user/store/reducer';
import patients from 'features/patients/store/reducer';
import patient from 'features/patient/store/reducer';
import filters from 'features/filters/store/reducer';
import trends from 'features/trends/store/reducer';
import { reducer as notificationsReducer } from 'reapop';

const rootReducer = combineReducers({
  authentication,
  application,
  user,
  patients,
  patient,
  filters,
  trends,
  notifications: notificationsReducer(),
});

export default rootReducer;
