import { Box } from '@mui/material';
import { useEffect, createRef } from 'react';
import { getChartConfig } from './chartConfig';
import { Chart } from 'chart.js';
import { wrapItInAMoment } from 'common/utils/_moment';
import { getHourAsBase10, getBarColor } from 'features/patients/_utils';
import moment from 'moment';

const TherapyUsageChart = ({ sessions, usage }) => {
  const chartRef = createRef();
  useEffect(() => {
    // sort by latest,
    // filter out all sessions but last month
    const cutoff = moment().utc().subtract(30, 'd').format('YYYYMMDD');
    const reverseUsage = usage?.reverse();
    let data =
      sessions?.filter(
        (session) => wrapItInAMoment(session?.start_date).format('YYYYMMDD') > cutoff
      ) ?? [];
    // console.log("as hours", data[0].periods[0].start,moment.duration(wrapItInAMoment(data[0].periods[0].start).format("HH:mm")).asHours().toFixed(2))
    // get min and max values of session to set charts Y axis min/max
    const minMax = [];
    // data.map(session => {
    //     mixMax.push(wrapItInAMoment(sessions.periods.start)
    //     sessions.periods.start
    // } )

    // fill out missing days with undefined value
    let dataIdx = 0;
    // let start =
    const chartData = [];
    const backgroundColor = [];
    const noData = Array(30).fill(true);
    Array(30)
      .fill(0)
      .forEach((_, i) => {
        if (dataIdx < data.length) {
          let currentDay = wrapItInAMoment(data[0]?.start_date).subtract(i, 'd').format('YYYYMMDD');
          if (currentDay === wrapItInAMoment(data[dataIdx]?.start_date).format('YYYYMMDD')) {
            data[dataIdx].periods.forEach((period, j) => {
              if (!chartData[j]) chartData[j] = Array(30).fill(undefined);
              if (!backgroundColor[j]) backgroundColor[j] = Array(30).fill(undefined);
              noData[i] = false;
              let start = getHourAsBase10(data[dataIdx]?.periods[j].start);
              let end = getHourAsBase10(data[dataIdx]?.periods[j].end);
              if (start > end) end += 24;
              // console.log('start > end',start > end,start,end)
              minMax.push(start, end);
              chartData[j][i] = [start, end];
              backgroundColor[j][i] = getBarColor(reverseUsage[i]);
            });
            dataIdx += 1;
          }
        }
      });

    const annotations = {};
    noData.forEach((bool, i) => {
      if (bool) {
        annotations['box' + i] = {
          drawTime: 'afterDraw',
          type: 'box',
          xMin: i - 0.2,
          xMax: i + 0.2,
          yMin: Math.max(minMax),
          yMax: Math.max(minMax) + 1,
          backgroundColor: 'rgba(0, 0, 0, 0',
          borderColor: 'rgba(255, 99, 132, 1)',
        };
      }
    });

    const myChartRef = chartRef.current.getContext('2d');
    const config = getChartConfig({ chartData, backgroundColor, annotations });
    const newChart = new Chart(myChartRef, config);

    newChart.noData = noData;
    newChart.update();

    return () => newChart.destroy();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className='chart-container' sx={{ position: 'relative', height: '28vh', width: '100%' }}>
      <canvas id={`therapy-usage`} ref={chartRef}></canvas>
    </Box>
  );
};

export default TherapyUsageChart;
