import React from 'react';
import Call from '@mui/icons-material/Call';
import PropTypes from 'prop-types';

const CallIcon = ({ size }) => {
  return (
    <div data-testid='call-icon'>
      <Call fontSize={size} />
    </div>
  );
};

CallIcon.propTypes = {
  size: PropTypes.string,
};

export default CallIcon;
