import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment';
import moment from 'moment';

Chart.register(annotationPlugin);

export const getChartConfig = ({ chartData, backgroundColor, annotations }) => ({
  type: 'bar',
  data: {
    labels: [...Array(30).keys()].map((i) => moment().utc().subtract(i, 'd').format('M/D')),
    datasets: [{ data: chartData.slice(0, 30), ...barConfig, backgroundColor: backgroundColor }],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    normalized: true,
    // parsing: false,
    animation: false,
    plugins: {
      annotation: {
        annotations: {
          ...annotations,
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        // reverse: true,
        display: false,
      },
      y: {
        display: false,
        ticks: {
          stepSize: 4,
          autoSkip: false,
          // callback: function(label, index, labels) {
          //     let l = label
          //     if (l > 24 ) l-= 24
          //     return moment(parseFloat(l), ["HH:mm"]).format("ha")
          //   }
        },
      },
    },
  },
});

const barConfig = {
  // barThickness: 20,
  // barPercentage: 1,
  // categoryPercentage: 1
};
