import { useMemo } from 'react';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import moment from 'moment';

/**
 * Utility function that allows you to use Material UI 4 makestyles classes with Material UI 5 emotion
 * https://dev.to/atonchev/material-ui-5-the-easiest-way-to-migrate-from-makestyles-to-emotion-1i9l
 **/
export const useClasses = (stylesElement) => {
  const theme = useTheme();
  return useMemo(() => {
    const rawClasses = typeof stylesElement === 'function' ? stylesElement(theme) : stylesElement;
    const prepared = {};

    Object.entries(rawClasses).forEach(([key, value = {}]) => {
      prepared[key] = css(value);
    });

    return prepared;
  }, [stylesElement, theme]);
};

export const createReducer = (initialState, handlers) => {
  return (state = initialState, actions) => {
    if (handlers.hasOwnProperty(actions.type)) {
      return handlers[actions.type](state, actions);
    } else {
      return state;
    }
  };
};

export const makeActionCreator = (type, ...argNames) => {
  return (args) => {
    const argList = !!argNames ? argNames : [];
    const parsedArgs = argList.reduce((acc, argName) => {
      if (!args.hasOwnProperty(argName)) {
        return acc;
      }

      return {
        ...acc,
        [argName]: args[argName],
      };
    }, {});
    return { type, ...parsedArgs };
  };
};

export const resetStore = (state, actions, initialState) => {
  return {
    ...initialState,
  };
};

export const updatingStore = (state, actions) => {
  return {
    ...state,
    isUpdating: true,
  };
};

/**
 *
 */
export const successfulUpdate = (state, actions, fields) => {
  const fieldList = !!fields ? fields : [];
  const additionalFields = fieldList.reduce((acc, field) => {
    if (actions.hasOwnProperty(field)) {
      return { ...acc, [field]: actions[field] };
    } else {
      return acc;
    }
  }, {});

  return {
    ...state,
    isUpdating: false,
    error: '',
    ...additionalFields,
  };
};

export const failedUpdate = (state, actions) => {
  return {
    ...state,
    isUpdating: false,
    error: actions.error,
  };
};

export const clearErrors = (state, actions) => {
  return {
    ...state,
    error: '',
  };
};

const isoregex =
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;

export const friendlyString = (value) => {
  if (value == null) {
    return 'None';
  }

  if (typeof value === 'string') {
    // Check if ISO date
    if (value.match(isoregex)) {
      // Recommendation is not to use Date.parse()
      return moment.utc(value).local().format('MM-DD-YY h:mm A');
    }
    return value;
  }

  if (Array.isArray(value)) {
    if (!value.length) {
      return 'None';
    }
    return value.join(', ');
  }

  if (typeof value === 'boolean') {
    return value ? 'True' : 'False';
  }

  // Dictionary
  if (value.constructor === Object) {
    return JSON.stringify(value, null, '\t');
  }

  // Cast to string
  return value + '';
};

export const prettifySnakeCase = (snakeCase) => {
  // Capitalize first letter of each word and replace hyphens & underscores with spaces
  let prettyLabel = snakeCase.toLowerCase().split(/-|_|\s+/);
  for (let i = 0; i < prettyLabel.length; i++) {
    prettyLabel[i] = prettyLabel[i].charAt(0).toUpperCase() + prettyLabel[i].substring(1);
  }
  return prettyLabel.join(' ');
};

export const formatName = (first, last, truncate = false, empty = '---') => {
  if (first && last) {
    if (truncate) {
      return `${first.charAt(0)}. ${last}`;
    } else {
      return `${first} ${last}`;
    }
  } else {
    return first || last || empty;
  }
};

export const formatEmptyValue = (value) => {
  if (value === null || value === '') {
    return '--';
  }
  return value;
};

export const successfulUpdateNonReload = (state, actions, fields) => {
  const fieldList = !!fields ? fields : [];
  const additionalFields = fieldList.reduce((acc, field) => {
    if (actions.hasOwnProperty(field)) {
      return { ...acc, [field]: actions[field] };
    } else {
      return acc;
    }
  }, {});

  return {
    ...state,
    ...additionalFields,
  };
};

/**
 * Flatten a multidimensional object
 *
 * For example:
 *   flattenObject{ a: 1, b: { c: 2 } }
 * Returns:
 *   { a: 1, c: 2}
 */
export const flattenObject = (obj) => {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};
