import { SUPER } from 'common/constants/roles';
import { createSelector } from 'reselect';
import { Permissions } from 'common/constants/permissions';

const getUserState = (state) => state.user || {};

const getIsUpdating = createSelector(getUserState, (userState) => userState.isUpdating);

const getUserId = createSelector(getUserState, (userState) => userState.id);

const getUserClinic = createSelector(getUserState, (userState) => userState.clinic);

const getUserClinicRef = createSelector(getUserState, (userState) => userState.clinic_ref);

const getUserRoles = createSelector(getUserState, (userState) => userState.roles);

const getUserIsSuper = createSelector(getUserRoles, (roles) => roles?.includes(SUPER));

const getUserPermissions = createSelector(getUserState, (userState) => userState.permissions);

const getUserSettings = createSelector(getUserState, (userState) => userState.settings || {});

const getUserTrackingEnabled = createSelector(
  getUserSettings,
  (userSettings) => userSettings.tracking_preference
);

const getUserEmailQueue = createSelector(
  getUserState,
  getUserSettings,
  (userSettings) => userSettings.settings.study_management_queue_email || []
);

const getUserTrackingToggleEnabled = createSelector(
  getUserSettings,
  (userSettings) => userSettings.tracking_toggle_enabled
);

const getUserViewerEnabled = createSelector(
  getUserSettings,
  getUserRoles,
  (userSettings, userRoles) => userSettings.enable_viewer || userRoles.includes(SUPER)
);

const getUserCanAssign = createSelector(getUserPermissions, (perms) =>
  perms.includes(Permissions.STUDY_ASSIGN)
);

const getUserCanChangeState = createSelector(getUserPermissions, (perms) =>
  perms.includes(Permissions.STUDY_CHANGE_STATE)
);

const getUserCanLockStudy = createSelector(getUserPermissions, (perms) =>
  perms.includes(Permissions.STUDY_LOCK)
);

const getUserCanUnlockStudy = createSelector(getUserPermissions, (perms) =>
  perms.includes(Permissions.STUDY_UNLOCK)
);

const getUserCohort = createSelector(getUserState, (userState) => userState.cohort);

const getUserFirstName = createSelector(getUserState, (userState) => userState.first);

const getUserLastName = createSelector(getUserState, (userState) => userState.last);

const getEmail = createSelector(getUserState, (userState) => userState.email);

const getUserFullName = createSelector(
  getUserFirstName,
  getUserLastName,
  (firstName, lastName) => `${firstName} ${lastName}`
);

const getError = createSelector(getUserState, (userState) => userState.error);

export default {
  getUserState,
  getIsUpdating,
  getUserId,
  getUserClinic,
  getUserCohort,
  getUserClinicRef,
  getUserRoles,
  getUserIsSuper,
  getUserPermissions,
  getUserFullName,
  getUserFirstName,
  getUserLastName,
  getUserTrackingEnabled,
  getUserEmailQueue,
  getUserSettings,
  getUserTrackingToggleEnabled,
  getUserViewerEnabled,
  getUserCanAssign,
  getUserCanChangeState,
  getUserCanLockStudy,
  getUserCanUnlockStudy,
  getEmail,
  getError,
};
