import axios from 'axios';
import store from 'store';
import { BASE_URL } from './conf';
import { handleRequestException } from './_utils';

export const AUTH_LOGIN_ENDPOINT = '/auth/login';
export const AUTH_SUPER_LOGIN_ENDPOINT = '/auth/super_login';
export const AUTH_REFRESH_ENDPOINT = '/auth/refresh';
export const AUTH_LOGOUT_ENDPOINT = '/auth/logout';
export const AUTH_PASSWORD_RESET = '/auth/reset_password';
export const AUTH_CONFIRM = '/auth/confirm';
export const AUTH_CHANGE_PASSWORD = '/auth/change_password';
export const AUTH_DOMAIN_PROVIDER = '/auth/provider';
export const CSRF_REFRESH_TOKEN = 'csrf_refresh_token';
export const CSRF_ACCESS_TOKEN = 'csrf_access_token';
export const CSRF_HEADER = 'X-CSRF-Token';

const authClient = axios.create({
  baseURL: BASE_URL,
  maxRedirects: 5,
  headers: { 'Content-Type': 'application/json' },
  credentials: process.env.NODE_ENV === 'development' ? 'same-origin' : undefined,
});

authClient.defaults.withCredentials = true;

authClient.interceptors.response.use((response) => {
  return response;
}, handleRequestException);

export const login = async (email, password) => {
  const res = await authClient.post(AUTH_LOGIN_ENDPOINT, {
    email,
    password,
    preventRedirectAfterProvider: true,
  });
  // console.log(store)
  store.set(CSRF_REFRESH_TOKEN, res.data.data[CSRF_REFRESH_TOKEN]);
  store.set(CSRF_ACCESS_TOKEN, res.data.data[CSRF_ACCESS_TOKEN]);
  return res;
};

export const superLogin = (email, password) => {
  return authClient.post(AUTH_SUPER_LOGIN_ENDPOINT, {
    email,
    password,
  });
};

export const refreshAccessToken = async () => {
  const res = await authClient.post(
    AUTH_REFRESH_ENDPOINT,
    {},
    {
      headers: {
        [CSRF_HEADER]: store.get(CSRF_REFRESH_TOKEN), // Will throw a 401 if this is undefined/exempt
      },
    }
  );
  store.set(CSRF_ACCESS_TOKEN, res.data.data[CSRF_ACCESS_TOKEN]);
  return res;
};

export const logout = async () => {
  try {
    const res = await authClient.post(
      AUTH_LOGOUT_ENDPOINT,
      {},
      {
        headers: {
          [CSRF_HEADER]: store.get(CSRF_REFRESH_TOKEN),
        },
      }
    );
    store.remove(CSRF_REFRESH_TOKEN);
    store.remove(CSRF_ACCESS_TOKEN);
    return res;
  } catch (e) {
    console.error(e);
  }
};

export const resetPassword = (email) => {
  return authClient.post(AUTH_PASSWORD_RESET, { email });
};

export const confirm = async (token) => {
  return (await authClient.post(AUTH_CONFIRM, { token })).data.data.reset_password_token;
};

export const changePassword = (token, password) => {
  return authClient.post(AUTH_CHANGE_PASSWORD, { token, password });
};

export const getProviderDomains = () => {
  return authClient.get(AUTH_DOMAIN_PROVIDER);
};

export const getProviderRedirect = async (emailOrDomain) => {
  const res = await authClient.get(`${AUTH_DOMAIN_PROVIDER}/${encodeURIComponent(emailOrDomain)}`, {
    headers: {
      'X-Redirect': false,
    },
  });
  return res.data.data.url;
};
