import React from 'react';
import { Link } from 'react-router-dom';
import { logoSrc } from 'common/assets';
import styled from 'styled-components';
import { Grid, TextField, Button } from '@mui/material';
import { resetPassword } from 'features/authentication/auth.api';
// import Button from 'components/atoms/loading-button/LoadingButton';
import { notify, STATUSES } from 'reapop';
// import { SubmitButton } from 'common/components';
import { useNavigate } from 'react-router';
import { useState } from 'react';

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
`;

const LogoBanner = (props) => {
  const Wrapper = styled.a`
    width: 100%;
    display: flex;
    justify-content: center;
  `;
  const StyledImage = styled.img`
    max-width: 849px;
    width: 80%;
    height: auto;
    margin-bottom: 30px;
  `;
  return (
    <Wrapper href={'https://www.ensodata.com/'}>
      <StyledImage src={logoSrc} {...props} />
    </Wrapper>
  );
};

const StyledTextField = styled(TextField)``;

const EmailField = (props) => {
  const newProps = Object.assign({}, props, {
    label: 'Email',
    variant: 'filled',
  });
  return <StyledTextField {...newProps} />;
};

const TextFieldContainer = styled.div`
  max-width: 500px;
  width: 90%;
  margin-top: 15px;
`;

const SubmitButton = (props) => {
  const StyledButton = styled(Button)`
    padding: 10px 20px;
    margin-top: 30px;
  `;
  return (
    <StyledButton color={'primary'} variant={'contained'} {...props}>
      Reset Password
    </StyledButton>
  );
};

const BackToLoginLink = (props) => {
  const Wrapper = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 10px;
  `;
  return (
    <Wrapper>
      <Link to={'/login'} {...props}>
        Back to Login
      </Link>
    </Wrapper>
  );
};

const ResetPasswordView = () => {
  const notifyAction = notify();
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    try {
      await resetPassword(email);
      notifyAction({
        title: 'An email has been sent to you with further instructions.',
        status: STATUSES.success,
      });
      navigate('/login');
    } catch (e) {
      notifyAction({
        title: e.message,
        status: STATUSES.error,
      });
    }
  };

  const onKeyPress = (e) => {
    if (e.which === 13) {
      return handleSubmit();
    }
  };

  return (
    <Container>
      <LogoBanner />
      <Grid>
        <Grid container justifyContent='center'>
          <TextFieldContainer>
            <EmailField fullWidth onChange={handleEmailChange} onKeyPress={onKeyPress} />
          </TextFieldContainer>
        </Grid>
        <Grid container justifyContent='center'>
          <SubmitButton onClick={handleSubmit} />
        </Grid>
        <Grid container justifyContent='center'>
          <BackToLoginLink />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResetPasswordView;
