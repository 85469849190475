import React from 'react';
import TaskListItem from 'features/patient/TasksPane/TaskListItem/TaskListItem';
import { prettifySnakeCase } from '../../../../common/utils/utils';
import { LoadingPineapple } from 'common/components';
import { Grid, Typography } from '@mui/material';
import moment from 'moment-timezone';

const TaskList = ({
  list,
  dateLabel,
  listType,
  tasksLoaded,
  onUpdateTaskItem,
  onReadOnlyTaskItem,
}) => {
  return (
    <Grid
      container
      xs={11}
      justifyContent='center'
      alignItems='center'
      sx={{ width: '100%', margin: '0 auto' }}
    >
      <Grid
        container
        sx={{
          padding: '0',
          marginBottom: '1rem',
          width: '100%',
          borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
        }}
      >
        <Grid xs={1} sx={{ paddingLeft: '.5rem' }}>
          <Typography>{'Type'}</Typography>
        </Grid>
        <Grid xs={9} sx={{ paddingLeft: '.75rem' }}>
          <Typography>{'Description'}</Typography>
        </Grid>
        <Grid xs={2} sx={{ paddingLeft: '.75rem' }}>
          <Typography>{prettifySnakeCase(dateLabel)}</Typography>
        </Grid>
      </Grid>
      <div style={{ overflow: 'scroll', height: '66vh', display: 'block', width: '100%' }}>
        <Grid sx={{ width: '100%' }}>
          {tasksLoaded &&
            list.map((task, index) => {
              let date = task.completed ?? task.due;
              let formattedDate = moment(date).format('MM/DD/YY');
              let id = task.id;
              return (
                <TaskListItem
                  key={index}
                  onClick={() =>
                    task.completed
                      ? onReadOnlyTaskItem(id, task.summary, task.completed)
                      : onUpdateTaskItem(id, task.summary)
                  }
                  message={task.summary}
                  type={task.type}
                  date={formattedDate}
                  color={task.completed ? '#4EA753' : '#E96500'}
                />
              );
            })}
          {!tasksLoaded && (
            <Grid>
              <LoadingPineapple shouldSpin={true} />
            </Grid>
          )}
          {!list.length && (
            <Typography>{`There are no ${listType} tasks for this patient`}</Typography>
          )}
        </Grid>
      </div>
    </Grid>
  );
};

export default TaskList;
