import { createReducer, updatingStore, successfulUpdate, resetStore } from 'common/utils/utils';

const initialState = {
  isUpdating: false,
  isQuerying: false,
  patients: [],
  hasNext: false,
  count: 0,
  page: 0,
  lastUpdated: null,
  queryPages: {},
  queryCount: 0,
  search: {
    isSearching: false,
    patients: [],
    search: '',
    error: '',
  },
  processing: {
    isprocessing: false,
    error: '',
  },
  current: {},
  error: '',
};

export const PatientsActions = {
  GET_PATIENTS_REQUEST: 'GET_PATIENTS_REQUEST',
  SEARCH_PATIENTS_SUCCESS: 'SEARCH_PATIENTS_SUCCESS',
  SEARCH_PATIENTS_FAILURE: 'SEARCH_PATIENTS_FAILURE',
  RESET_PATIENTS_REQUEST: 'RESET_PATIENTS_REQUEST',
  // PROCESS_PATIENTS_SUCCESS: 'PROCESS_PATIENTS_SUCCESS',
  // PROCESS_PATIENTS_FAILURE: 'PROCESS_PATIENTS_FAILURE',
};

const patientsReducer = createReducer(initialState, {
  [PatientsActions.GET_PATIENTS_REQUEST]: (state, actions) =>
    updatingStore(
      {
        ...state,
        search: {
          ...state.search,
          isSearching: true,
        },
      },
      actions
    ),
  [PatientsActions.SEARCH_PATIENTS_SUCCESS]: (state, actions) =>
    successfulUpdate(state, actions, [
      'patients',
      'count',
      'hasMore',
      'lastUpdated',
      'page',
      'search',
    ]),
  [PatientsActions.SEARCH_PATIENTS_FAILURE]: (state, actions) => ({
    ...state,
    search: {
      error: actions.error,
      isSearching: false,
    },
  }),
  [PatientsActions.RESET_PATIENTS_REQUEST]: (state, actions) => {
    const newState = resetStore(state, actions, initialState);
    if (actions.except) {
      actions.except.forEach((key) => {
        newState[key] = state[key];
      });
    }
    return newState;
  },
  // [PatientsActions.PROCESS_PATIENTS_SUCCESS]: (state, actions) => ({
  //   ...state,
  //   patients: actions.patients, // TODO: change base to queryPatietns
  //   search: {
  //     ...state.search,
  //     patients: actions.searchResults,
  //   },
  //   processing: {
  //     error: '',
  //     isProcessing: false,
  //   },
  // }),
  // [PatientsActions.PROCESS_PATIENTS_FAILURE]: (state, actions) => ({
  //   ...state,
  //   processing: {
  //     isProcessing: false,
  //     error: actions.error,
  //   },
  // }),
});

export default patientsReducer;
