import React from 'react';
import Icon from '@mui/material/Icon';
import styled from 'styled-components';

const StyledImg = styled.img`
  height: inherit;
`;

const SvgAsIcon = (svgImage) => {
  return (props) => (
    <Icon {...props}>
      <StyledImg src={svgImage} />
    </Icon>
  );
};

export default SvgAsIcon;
