import React, { useCallback, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import { styles } from './PatientsView.style';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary } from 'common/components';
import { LoadingPineapple } from 'common/components';
import { Link } from 'react-router-dom';
import {
  // TODO: remove unused code
  // MuiThemeProvider,
  TablePagination,
  Tooltip,
  Typography,
  Container,
  Box,
  IconButton,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  TableCell,
} from '@mui/material';
import {
  Edit,
  // Lock,
  Clear,
  // GetApp as DownloadIcon,
  NavigateNext,
  FemaleSharp,
} from '@mui/icons-material';
// import { PatientDataFields, HOLD_STATUS, SIGNAL_READ_STATUS } from 'utils/patientViewUtils';
import { getPatientsRequest } from 'features/patients/store/actions';
import { PAGE_SIZE } from 'features/patients/store/sagas';
import userSelectors from 'features/user/store/selectors';
import patientsSelectors from 'features/patients/store/selectors';
import applicationSelectors from 'features/application/store/selectors';
// import managementSelectors from 'store/management/selectors';
import { useState } from 'react';
import { useEffect } from 'react';
// import FiltersHeader from './FiltersHeader/FiltersHeader';
import { useInterval } from 'common/utils/customHooks';
import { isEmpty } from 'lodash';
import RefreshList from 'features/patients/RefreshList/RefreshList';
import { formatName, useClasses } from 'common/utils/utils';
import { isEqual } from 'lodash';
import {
  updateSortOptionsRequest,
  clearSortOptionsRequest,
  initSortOptionsRequest,
} from 'features/filters/store/actions';
import { SortDirectionButton } from 'common/components';
import filtersSelectors from 'features/filters/store/selectors';
import { LocalStorageInterface } from 'common/utils/localStorageInterface';
import { getDaysSinceTreatmentStart } from 'features/patients/_utils';
import TherapyUsageSpark from 'features/patients/TherapyUsageSpark/TherapyUsageSpark';
import ComplianceSpark from 'features/patients/ComplianceSpark/ComplianceSpark';
import { StorageTypes } from 'common/constants/storage';
// import { PatientsExportDialog } from './PatientsExportDialog/PatientsExportDialog';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const MUIDataTableTheme = () =>
  createTheme({
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            paddingLeft: '0px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '1px 1px 1px 10px',
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            padding: '2px 5px 2px 5px',
          },
        },
      },
    },
  });

const DEFAULT_POLLING_REQUENCY = 60000; // 1 minute

// TODO: change cohort to permission

// const formatPatientName = (pat) => {
//   const { first_name, last_name } = pat.phi;
//   return formatName(first_name, last_name);
// };

// const formatPatientBirthDate = (pat) => {
//   return formatDate(pat.phi.birth_date);
// };

// const formatLockedByName = (pat) => {
//   const { first, last } = pat.management.locked_by;
//   return formatName(first, last);
// };

// const formatLockedAt = (pat) => {
//   return formatDate(pat.management.locked_at);
// };

function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

const calcTableColumnProps = (
  isSuper,
  dispatch,
  classes,
  softwares,
  applySortOptionsChange,
  sortOptionsSelector,
  page
) => {
  // TODO: If a new column is added to columnProps, 'tableMappings' should be updated in PatientsExportDialog
  let columnProps = [
    {
      name: 'Compliance',
      picker: (pat) => pat,
      options: {
        customHeadRender: ({ index, ...column }) => {
          let sortOption =
            sortOptionsSelector['current_compliance'] !== undefined
              ? sortOptionsSelector['current_compliance']
              : { sortColumn: 'current_compliance', sortDirection: 0 };
          return (
            <TableCell key={index}>
              <SortDirectionButton
                label={column.name}
                column={sortOption.sortColumn}
                direction={sortOption.sortDirection}
                onChange={applySortOptionsChange}
              />
            </TableCell>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return <ComplianceSpark index={`${tableMeta.rowIndex}-${page}`} treatment={value} />;
        },
      },
    },
    {
      name: 'Tx Usage',
      picker: (pat) => pat?.usage,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log('Avg Therapy Usage', tableMeta, updateValue)
          return <TherapyUsageSpark index={`${tableMeta.rowIndex}-${page}`} chartData={value} />;
        },
      },
    },
    {
      name: '% Change',
      picker: (pat) => pat?.compliance_change?.day_1 ?? 0,
      options: {
        customHeadRender: ({ index, ...column }, sortColumn) => {
          let sortOption =
            sortOptionsSelector['compliance_change.day_1'] !== undefined
              ? sortOptionsSelector['compliance_change.day_1']
              : { sortColumn: 'compliance_change.day_1', sortDirection: 0 };
          return (
            <TableCell key={index}>
              <SortDirectionButton
                label={column.name}
                column={sortOption.sortColumn}
                direction={sortOption.sortDirection}
                onChange={applySortOptionsChange}
              />
            </TableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          let color = { 1: 'green', '-1': 'red', 0: 'gray' };

          return (
            <Box
              // component={'span'}
              display='flex'
              sx={{
                borderRadius: '.5rem',
                background: color[Math.sign(value.toFixed(1))],
                margin: '.2rem',
                width: '3rem',
                height: '1.2rem',
                textAlign: 'center',
                color: 'white',
              }}
            >
              {/* <Typography variant='caption' sx={{margin: 'auto'}}>{moment.duration(complianceAverage *3600*1000).asMinutes().toFixed(1)}M μ</Typography> */}
              <Typography variant='caption' sx={{ margin: 'auto' }}>
                {value.toFixed(1)} %
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: 'Risk Score',
      picker: (pat) => pat?.risk_predictions?.day_7 ?? 0,
      options: {
        customHeadRender: ({ index, ...column }, sortColumn) => {
          let sortOption =
            sortOptionsSelector['risk_predictions.day_7'] !== undefined
              ? sortOptionsSelector['risk_predictions.day_7']
              : { sortColumn: 'risk_predictions.day_7', sortDirection: 0 };
          return (
            <TableCell key={index}>
              <SortDirectionButton
                label={column.name}
                column={sortOption.sortColumn}
                direction={sortOption.sortDirection}
                onChange={applySortOptionsChange}
              />
            </TableCell>
          );
        },
      },
    },
    {
      name: 'Avg AHI',
      picker: (pat) => 0,
    },
    {
      name: 'Tx Days',
      picker: (pat) => getDaysSinceTreatmentStart(pat?.first_available_session),
      options: {
        customHeadRender: ({ index, ...column }, sortColumn) => {
          let sortOption =
            sortOptionsSelector['first_available_session'] !== undefined
              ? sortOptionsSelector['first_available_session']
              : { sortColumn: 'first_available_session', sortDirection: 0 };
          return (
            <TableCell key={index}>
              <SortDirectionButton
                label={column.name}
                column={sortOption.sortColumn}
                direction={sortOption.sortDirection}
                onChange={applySortOptionsChange}
                reverse={true}
              />
            </TableCell>
          );
        },
      },
    },
    {
      name: 'Patient',
      picker: (pat) => formatName(pat?.phi?.first_name, pat?.phi?.last_name),
      options: {
        customHeadRender: ({ index, ...column }, sortColumn) => {
          let sortOption =
            sortOptionsSelector['last_name'] !== undefined
              ? sortOptionsSelector['last_name']
              : { sortColumn: 'last_name', sortDirection: 0 };
          return (
            <TableCell key={index}>
              <SortDirectionButton
                label={column.name}
                column={sortOption.sortColumn}
                direction={sortOption.sortDirection}
                onChange={applySortOptionsChange}
              />
            </TableCell>
          );
        },
      },
    },
    {
      name: 'Alerts',
      picker: (pat) => 0,
    },
    {
      name: 'Last Contacted',
      picker: (pat) => moment().format('MM/DD/YY'),
    },
    {
      name: '',
      picker: (pat) => pat,
      options: {
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Link to={`/patients/${value.patient}/treatment/${value.id}`}>
              <NavigateNext sx={{ color: 'black' }} />{' '}
            </Link>
          );
        },
      },
    },
  ];

  return columnProps;
};

const PatientsView = ({ pollingFrequency = DEFAULT_POLLING_REQUENCY }) => {
  const classes = useClasses(styles);
  const dispatch = useDispatch();
  const isSuper = useSelector(userSelectors.getUserIsSuper);
  // const viewerEnabled = useSelector(userSelectors.getUserViewerEnabled);
  // const canAssign = useSelector(userSelectors.getUserCanAssign);
  // const isUpdating = useSelector(patientsSelectors.getIsUpdating);
  const searchRunning = useSelector(patientsSelectors.getIsSearching);
  const softwares = useSelector(applicationSelectors.getSoftwares);
  const [refreshActive, setRefreshActive] = useState(false);
  const page = useSelector(patientsSelectors.getPage);
  const isProcessing = useSelector(patientsSelectors.getIsProcessing);
  // TODO: Remove unused code
  // eslint-disable-next-line no-unused-vars
  const [rowsSelected, setRowsSelected] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [dialogOpen, setDialogOpen] = useState(false);
  const lastUpdated = useSelector(patientsSelectors.getLastUpdated);
  const querySearch = useSelector(patientsSelectors.getQuerySearch);
  const queryResults = useSelector(patientsSelectors.getPatients);
  // const searchResults = useSelector(patientsSelectors.getSearchResults) || [];
  const count = useSelector(patientsSelectors.getCount);
  const sortOptionsSelector = useSelector(filtersSelectors.getSortOptions);
  const isSearching = !!querySearch?.length;
  // const patients = isSearching ? searchResults : queryResults;
  const patients = queryResults;
  // const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  // const [downloadedColumns, setDownloadedColumns] = useState([]);

  useEffect(() => {
    const sortOptions = new LocalStorageInterface(StorageTypes.SORT_ORDER).getKey();
    if (sortOptions) {
      dispatch(initSortOptionsRequest({ sortOptions }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    new LocalStorageInterface(StorageTypes.SORT_ORDER, sortOptionsSelector).setKey();
  }, [sortOptionsSelector]);

  const applySortOptionsChange = useCallback(
    (sortColumn, sortDirection) => {
      const sortOptions = { sortColumn: sortColumn, sortDirection: sortDirection };
      dispatch(updateSortOptionsRequest({ sortOptions }));
    },
    [dispatch]
  );

  const clearSortOptionsChange = useCallback(() => {
    dispatch(clearSortOptionsRequest());
    new LocalStorageInterface(StorageTypes.SORT_ORDER).deleteKey();
  }, [dispatch]);

  const tableColumnProps = useMemo(
    () =>
      calcTableColumnProps(
        isSuper,
        dispatch,
        classes,
        softwares,
        applySortOptionsChange,
        sortOptionsSelector,
        page
      ),
    [isSuper, dispatch, classes, softwares, applySortOptionsChange, sortOptionsSelector, page]
  );

  const formattedPatients = useMemo(
    () => {
      return patients.map((pat) => tableColumnProps.map(({ picker }) => picker(pat)));
    },

    [useDeepCompareMemoize(patients), isProcessing, tableColumnProps] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //TODO: this could become problematic when managing assignments and a patient just "dissapears" or moves in the table
  useInterval(
    () => {
      if (isSearching) {
        dispatch(getPatientsRequest({}));
      } else {
        dispatch(getPatientsRequest({ page: 0 }));
      }
    },
    (isSearching && !isEmpty(patients)) || page === 0 ? pollingFrequency : null
  );

  useEffect(() => {
    dispatch(getPatientsRequest({ page: 0 }));
  }, []);

  // useEffect(() => {
  //   if (!refreshActive && isUpdating) {
  //     setRefreshActive(true);
  //   }

  //   if (refreshActive && !isUpdating) {
  //     const timer = setTimeout(() => {
  //       setRefreshActive(false);
  //     }, 2000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [isUpdating]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPageChange = (_, page) => {
    dispatch(getPatientsRequest({ page }));
  };

  const customToolbar = useCallback(() => {
    return (
      <div className={classes.customPatientsToolbar}>
        <TablePagination
          className={classes.customTablePagination}
          component='div'
          count={count}
          rowsPerPage={PAGE_SIZE}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          rowsPerPageOptions={[]}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={onPageChange}
        />
      </div>
    );
  }, [classes, count, page, dispatch]);

  const refreshList = useCallback(() => {
    setRefreshActive(true);
    dispatch(getPatientsRequest({ page: 0 }));
  }, [setRefreshActive, dispatch]);

  const tableOptions = useMemo(() => {
    const defaultTableOptions = {
      customToolbar: customToolbar,
      download: FemaleSharp,
      // downloadOptions: {
      //   filterOptions: {
      //     useDisplayedColumnsOnly: true,
      //   },
      // },
      // onDownload: (_buildHead, _buildBody, columns) => {
      //   setDownloadedColumns(columns.map(({ name }) => name).filter(Boolean));
      //   setIsExportModalOpen(true);
      //   return false;
      // },
      expandableRow: false,
      filter: false,
      fixedHeader: true,
      pagination: false,
      print: false,
      resizableColumns: false,
      responsive: 'standard',
      rowHover: false,
      rowsPerPage: PAGE_SIZE,
      search: false,
      selectableRows: 'none',
      // isSelectable: false,
      // expandableRowsHeader:false,
      // isRowExpandable: false,
      // TODO: turn this into a controlled component (right now, performance is better with uncontrolled due to less re-renders, just need to memoize)
      // rowsSelected: rowsSelected,
      // onRowSelectionChange: (_, __, val) => setRowsSelected(val),
      customToolbarSelect: (selectedData) => {
        return (
          <IconButton
            style={{ marginRight: '10px' }}
            onClick={() => {
              setRowsSelected(selectedData.data.map(({ dataIndex }) => dataIndex));
              setDialogOpen(true);
            }}
          >
            <Edit />
          </IconButton>
        );
      },
      sort: false,
      sortFilterList: false,
      textLabels: {
        body: {
          noMatch: searchRunning ? <LoadingPineapple /> : 'Sorry, no matching records found',
          toolTip: 'Sort',
        },
      },
    };

    // if (isSearching) {
    //   return {
    //     ...defaultTableOptions,
    //     customToolbar: null,
    //     serverSide: true,
    //     // sort: true,
    //     textLabels: {
    //       ...defaultTableOptions.textLabels,
    //       body: {
    //         noMatch: searchRunning ? <LoadingPineapple /> : 'Sorry, no matching records found',
    //         toolTip: 'Sort',
    //       },
    //     },
    //   };
    // } else {
    return {
      ...defaultTableOptions,
      count,
    };
    // }
  }, [customToolbar, searchRunning, count, isSearching, isSuper, classes]);

  const renderedTable = useMemo(() => {
    return (
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={MUIDataTableTheme()}>
          <MUIDataTable
            columns={tableColumnProps}
            title={
              <Box className={classes.flexRow}>
                <RefreshList
                  lastUpdate={lastUpdated}
                  handleRefresh={refreshList}
                  isUpdating={refreshActive}
                />
                <IconButton onClick={clearSortOptionsChange}>
                  <Tooltip title={'Clear Sort'}>
                    <Clear />
                  </Tooltip>
                </IconButton>
              </Box>
            }
            data={formattedPatients}
            options={tableOptions}
            // components={{
            //   icons: {
            //     DownloadIcon: () => <DownloadIcon />,
            //   },
            // }}
          />
        </ThemeProvider>
      </CacheProvider>
    );
  }, [
    tableColumnProps,
    lastUpdated,
    refreshList,
    refreshActive,
    formattedPatients,
    tableOptions,
    clearSortOptionsChange,
    classes,
  ]);
  return (
    <Container maxWidth='xl' className={classes.main} sx={{ marginBottom: '15px' }}>
      <ErrorBoundary>
        {/* <FiltersHeader includeSort includeSearch /> */}
        {/* <Dialog open={dialogOpen || isBatching} onClose={handleManagementFormClose}>
          <DialogTitle>Bulk Update State & Assignment</DialogTitle>
          <DialogContent>
            <PatientManagementForm
              loading={isBatching}
              onCompletion={handleManagementFormSubmit}
              onClose={handleManagementFormClose}
            />
          </DialogContent>
        </Dialog> */}
        {/* {availableTabs.length > 1 && (
          <StyledTabs value={tab} onChange={handleTabChange}>
            {availableTabs.map((name, idx) => (
              <StyledTab key={`patients-tab-${name}-${idx}`} label={name} value={name} />
            ))}
          </StyledTabs>
        )} */}
        {/* <PatientsExportDialog
          open={isExportModalOpen}
          closeModal={() => setIsExportModalOpen(false)}
          visibleColumns={downloadedColumns}
        /> */}
        {renderedTable}
      </ErrorBoundary>
    </Container>
  );
};

export default PatientsView;
