import * as React from 'react';
import PropTypes from 'prop-types';
import { pineappleSrc } from '../../assets/';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { keyframes } from '@mui/system';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Rotate = styled('div')({
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column nowrap',
  display: 'flex',
  width: '100%',
  height: '100vh',
  animation: `${spin} 3s infinite ease-in-out`,
});

const LoadingPineapple = ({ ...props }) => {
  const { loadingText } = props;

  return (
    <>
      <Rotate>
        <img src={pineappleSrc} alt='loading...' />
        <Typography variant={'overline'}>{loadingText}</Typography>
      </Rotate>
    </>
  );
};

LoadingPineapple.defaultProps = {
  shouldSpin: true,
  loadingText: '',
};

LoadingPineapple.propTypes = {
  shouldSpin: PropTypes.bool,
  loadingText: PropTypes.string,
};

export default LoadingPineapple;
