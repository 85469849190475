import React from 'react';
import { useSelector } from 'react-redux';
import selectors from 'features/trends/store/selectors';
import ComplianceBucketsChart from '../ComplianceBucketsChart/ComplianceBucketsChart';
import ItemGrid from '../../../common/components/ItemGrid/ItemGrid';
import { Grid, Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getTrendsRequest } from '../store/actions';
import { useEffect } from 'react';
import { LoadingPineapple } from 'common/components';

const TrendsView = () => {
  const complianceBuckets = useSelector(selectors.getComplianceBuckets);
  const loaded = useSelector(selectors.getLoaded);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTrendsRequest({}));
  }, []);

  if (!loaded) {
    return <LoadingPineapple shouldSpin={true} />;
  }

  return (
    <Container maxWidth='xl' sx={{ marginBottom: '15px' }} data-testid='trends-view'>
      {complianceBuckets?.length ? (
        <Grid container sx={{ padidng: 0 }}>
          <Grid item xs={12}>
            <Grid container direction='row' justify='space-evenly'>
              <ItemGrid sm={12} lg={12} data-testid='trends-30day-compliance--header'>
                <ComplianceBucketsChart complianceBuckets={complianceBuckets} />
              </ItemGrid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container textAlign='center'>
          <Grid item xs={12}>
            no results found
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default TrendsView;
