import { call, put, takeLatest } from 'redux-saga/effects';
import {
  loginSuccess,
  loginFailure,
  refreshProviderDomainsSuccess,
  refreshProviderDomainsFailure,
  refreshAuthSuccess,
  refreshAuthFailure,
  logoutSuccess,
  logoutFailure,
  superLoginSuccess,
  superLoginFailure,
} from './actions';
import {
  getProviderDomains,
  refreshAccessToken,
  login as apiLogin,
  logout as apiLogout,
  superLogin as apiSuperLogin,
} from '../auth.api';
import { AuthenticationActions } from './reducer';
import { loginPath } from 'common/utils/paths';
import { resetUser, fetchUserRequest } from '../../user/store/actions';
import { initializeApplicationRequest } from '../../application/store/actions';

function* login(actions) {
  try {
    yield call(apiLogin, actions.email, actions.password);
    yield put(initializeApplicationRequest());
    yield put(fetchUserRequest());
    yield put(loginSuccess({ isAuthenticated: true }));
  } catch (e) {
    yield put(loginFailure({ error: e.message }));
  }
}

function* superLogin(actions) {
  try {
    yield call(apiSuperLogin, actions.email, actions.password);
    yield put(superLoginSuccess({ isSuperUser: true }));
  } catch (e) {
    yield put(superLoginFailure({ error: e.message }));
  }
}

export function* refreshProviderDomains(actions) {
  try {
    const res = yield call(getProviderDomains);
    yield put(refreshProviderDomainsSuccess({ providerDomains: res.data.data }));
  } catch (e) {
    yield put(refreshProviderDomainsFailure({ error: e.message }));
  }
}

function* refreshAuth(actions) {
  try {
    yield call(refreshAccessToken);
    yield put(initializeApplicationRequest());
    yield put(fetchUserRequest());
    yield put(refreshAuthSuccess({ isAuthenticated: true }));
  } catch (e) {
    yield put(refreshAuthFailure());
  }
}

function* logout(actions) {
  try {
    const { navigate } = actions;

    yield call(apiLogout);
    yield put(logoutSuccess({ isAuthenticated: false }));
    yield call(navigate, loginPath);
    yield put(resetUser());
  } catch (e) {
    yield put(logoutFailure({ error: e.message }));
  }
}

function* authenticationSaga() {
  yield takeLatest(AuthenticationActions.LOGIN_REQUEST, login);
  yield takeLatest(AuthenticationActions.SUPER_LOGIN_REQUEST, superLogin);
  yield takeLatest(AuthenticationActions.REFRESH_PROVIDER_DOMAINS_REQUEST, refreshProviderDomains);
  yield takeLatest(AuthenticationActions.REFRESH_AUTH_REQUEST, refreshAuth);
  yield takeLatest(AuthenticationActions.LOGOUT_REQUEST, logout);
}

export default authenticationSaga;
