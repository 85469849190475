import { createSelector } from 'reselect';

const getAuthenticationState = (state) => {
  return state.authentication;
};

const getIsUpdating = createSelector(
  getAuthenticationState,
  (authenticationState) => authenticationState.isUpdating
);

const getIsAuthenticated = createSelector(
  getAuthenticationState,
  (authenticationState) => authenticationState.isAuthenticated
);

const getIsSuperUser = createSelector(
  getAuthenticationState,
  (authenticationState) => authenticationState.isSuperUser
);

const getProviderDomains = createSelector(
  getAuthenticationState,
  (authenticationState) => authenticationState.providerDomains
);

const getError = createSelector(
  getAuthenticationState,
  (authenticationState) => authenticationState.error
);

export default {
  getAuthenticationState,
  getIsUpdating,
  getIsAuthenticated,
  getProviderDomains,
  getIsSuperUser,
  getError,
};
