import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { lineColorPlugin } from 'common/plugins/plugins';

Chart.register(annotationPlugin);

export const getChartConfig = ({ chartData, compliant }) => ({
  type: 'line',
  data: {
    labels: [...Array(30).keys()]
      .reverse()
      .map((i) => moment().utc().subtract(i, 'd').format('M/D')),
    datasets: [
      {
        data: chartData.slice(0, 30),
        borderColor: compliant ? 'green' : 'red',
        pointRadius: 1,
      },
    ],
  },
  plugins: [lineColorPlugin],
  options: {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    normalized: true,
    plugins: {
      annotation: {
        annotations: {
          line: {
            type: 'line',
            yMin: 70,
            yMax: 70,
            borderColor: 'green',
            borderWidth: 1,
            borderDash: [2, 3],
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
        // reverse: true,
      },
      y: {
        display: false,
      },
    },
  },
});
