import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// TODO: remove used code
// import { SUPER, SIMULATOR } from 'constants/roles';
import {
  Button,
  // Divider,
  Paper,
  Popover,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import selectors from 'features/user/store/selectors';
import { useNavigate } from 'react-router-dom';
// import { resetSimulatedUser } from '../../../store/user/actions';
import { logoutRequest } from 'features/authentication/store/actions';
// import UserPopoverSuperUser from './UserPopoverSuperUser';
// import UserPopoverAnalytics from './UserPopoverAnalytics';
// import UserPopoverEmailQueue from './UserPopoverEmailQueue';
import { useClasses } from 'common/utils/utils';

const styles = {
  popupPaper: {
    padding: '1rem',
    width: '25rem',
  },
  dontFlexMyNames: {
    minWidth: 'max-content',
  },
  header: {
    textAlign: 'center',
    paddingBottom: '2rem',
  },
  centerBold: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1rem',
    paddingBottom: '1.25rem',
  },
  spacer: {
    margin: '1rem 0',
  },
  bold: {
    fontWeight: 600,
  },
  helpIcon: {
    paddingLeft: '.25rem',
  },
  analyticsHeader: {
    display: 'flex',
    alignItems: 'center',
  },
};

const UserPopover = () => {
  const classes = useClasses(styles);
  const roles = useSelector(selectors.getUserRoles);
  const userName = useSelector(selectors.getUserFullName);
  const userClinic = useSelector(selectors.getUserClinic);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  //   const isSuper = roles.some((role) => role === SUPER);
  //   const isSimulator = roles.some((role) => role === SIMULATOR);
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (evt) => {
    setAnchorEl(evt.currentTarget);
  };

  //   const onResetUser = () => {
  //     dispatch(resetSimulatedUser({ navigate }));
  //   };

  const logout = () => {
    dispatch(logoutRequest({ navigate }));
  };

  return (
    <>
      <Tooltip title='Profile'>
        <IconButton onClick={handleOpen} data-testid='user-popover-button'>
          <AccountCircleIcon fontSize='inherit' />
        </IconButton>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper className={classes.popupPaper} data-testid='user-popover-modal'>
          <Grid container direction='column' alignItems='center'>
            <Grid item className={classes.header}>
              <Typography variant='h6' className={classes.dontFlexMyNames}>
                {userName}
              </Typography>
              <Typography variant='caption'>{userClinic}</Typography>
              <br />
              <Typography variant='caption'>({`${roles?.join('-')}`})</Typography>
              <br />
            </Grid>
            {/* <UserPopoverAnalytics />
            <UserPopoverEmailQueue /> */}
            <Grid item>
              <Button onClick={logout} size='small'>
                Logout
              </Button>
            </Grid>
          </Grid>
          {/* {isSuper && <UserPopoverSuperUser onOpen={() => setAnchorEl(null)} />}
          {isSimulator && (
            <>
              <Divider className={classes.spacer} />
              <Typography
                variant='body1'
                className={classes.centerBold}
                data-testid='user-popup-simulator'
              >
                Advanced
              </Typography>
              <Grid container direction='column' alignItems='center'>
                <Grid item>
                  <Button onClick={onResetUser} size='small'>
                    Reset Super Role
                  </Button>
                </Grid>
              </Grid>
            </>
          )} */}
        </Paper>
      </Popover>
    </>
  );
};

export default UserPopover;
