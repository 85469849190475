import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { lineColorPlugin } from 'common/plugins/plugins';

Chart.register(annotationPlugin);

export const getChartConfig = ({ chartData, compliant, pointBorderColor }) => ({
  type: 'line',
  data: {
    labels: [...Array(31).keys()]
      .reverse()
      .map((i) => moment().utc().subtract(i, 'd').format('M/D')),
    datasets: [
      {
        data: chartData.slice(0, 30),
        borderColor: compliant ? 'green' : 'red',
        borderWidth: 2,
        pointRadius: 2,
        pointBorderColor,
        fill: false,
      },
    ],
  },
  plugins: [lineColorPlugin],
  options: {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    normalized: true,
    plugins: {
      annotation: {
        annotations: {
          line: {
            type: 'line',
            yMin: 70,
            yMax: 70,
            borderColor: 'green',
            borderWidth: 2,
            borderDash: [2, 3],
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      // elements: {
      //     line: {
      //         borderColor: '#000000',
      //         borderWidth: 1
      //     },
      //     point: {
      //         radius: 0
      //     }
      // },
      // tooltips: {
      //     enabled: false
      // }
    },
    scales: {
      x: {
        // reverse: true,
        grid: {
          display: true,
          drawBorder: false,
        },
        display: true,
        ticks: {
          display: false,
          font: {
            size: 10,
          },
        },
      },
      y: {
        // min: 0,
        // max: 100,
        afterFit: function (scaleInstance) {
          scaleInstance.width = 40;
        },
        display: true,

        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          font: {
            size: 10,
          },
          callback: function (label, index, labels) {
            return label + '%';
          },
        },
      },
    },
  },
});
