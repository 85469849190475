import { call, put, all, takeLatest } from 'redux-saga/effects';
import { ApplicationActions } from './reducer';
import { initializeApplicationSuccess, initializeApplicationFailure } from './actions';
import { getAbout, getSoftwares, getStatuses } from './api';

function* initializeApplicationState(actions) {
  try {
    const [about, statuses, softwares] = yield all([
      call(getAbout),
      call(getStatuses),
      call(getSoftwares),
    ]);

    yield put(
      initializeApplicationSuccess({
        about: about.data.data,
        statuses: statuses.data.data,
        softwares: softwares.data.data,
      })
    );
  } catch (e) {
    yield put(initializeApplicationFailure({ error: e.message }));
  }
}

function* applicationSagas() {
  yield takeLatest(ApplicationActions.INITIALIZE_APPLICATION_REQUEST, initializeApplicationState);
}

export function* initFetch({ user }) {
  // TODO do any initial permission settings here
  // const { id, clinic_ref, roles, permissions } = user;
}

export default applicationSagas;
