// import { REUPLOAD_STATUS, REPROCESS_STATUS, REDOWNLOAD_STATUS, REVERT_STATUS } from '../_utils';
import client from 'common/api/client';

// export const postPatients = (filters, query, pageIndex) => {
//   const endpoint = '/dashboard/page_pats';
//   return client.post(endpoint, { filters, query, pageIndex });
// };

export const getPatients = (query) => {
  const endpoint = '/internal/patient';
  return client.get(endpoint, {
    params: query,
  });
};

export const getTreatments = (query) => {
  const endpoint = '/treatment';
  return client.get(endpoint, {
    params: query,
  });
};

export const getTreatmentSessions = (query) => {
  const endpoint = '/treatment_session';
  return client.get(endpoint, {
    params: query,
  });
};

export const getActvities = (query) => {
  const endpoint = '/activity';
  return client.get(endpoint, {
    params: query,
  });
};

// export const patchReprocessPatient = (id, desat, setStatus) => {
//   const patchBody = {
//     set__settings__score__overrides__desat: desat,
//     set__settings__desat: desat, // For backwards compatibility
//   };

//   if (!!setStatus) {
//     patchBody.status = REPROCESS_STATUS;
//   }

//   return updatePatient(id, patchBody);
// };

// export const patchRedownloadPatient = (id) => {
//   return updatePatient(id, {
//     status: REDOWNLOAD_STATUS,
//   });
// };

// export const patchRevertPatient = (id) => {
//   return updatePatient(id, {
//     status: REVERT_STATUS,
//   });
// };

// export const patchStartPatientUpload = (id) => {
//   return updatePatient(id, {
//     status: REUPLOAD_STATUS,
//   });
// };

// export const patchManagementAssignee = (id, userId, step) => {
//   const endpoint = `/patient/${id}/set_assignee`;
//   return client.post(endpoint, {
//     assignee: userId,
//     step,
//   });
// };

// export const patchManagementAssigneeAndState = async (id, userId, state) => {
//   await patchManagementAssignee(id, userId, state);
//   return patchManagementState(id, state);
// };

// export const patchManagementState = (id, state) => {
//   const endpoint = `/patient/${id}/set_state`;
//   return client.post(endpoint, {
//     state: state,
//   });
// };

// const processBatchResponses = (responses, updatedPatients) => {
//   responses.forEach((response) => {
//     const pat = response.data.data;
//     updatedPatients[pat.id] = pat;
//   });
// };

// export const patchManagementBatch = async (ids, state, assignees) => {
//   // TODO: this can be optimized by creating a batch update endpoint. Right now, we're just firing off individual requests
//   const updatedPatients = {};
//   if (state !== undefined) {
//     const responses = await Promise.all(ids.map((id) => patchManagementState(id, state)));
//     processBatchResponses(responses, updatedPatients);
//   }
//   if (assignees) {
//     // Run sequentially, not in parallel, so that we avoid race conditions and have fully updated results
//     for (const [stepName, newAssignee] of Object.entries(assignees)) {
//       const responses = await Promise.all(
//         ids.map((id) => patchManagementAssignee(id, newAssignee, stepName))
//       );
//       processBatchResponses(responses, updatedPatients);
//     }
//   }

//   return Object.values(updatedPatients);
// };
