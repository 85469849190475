export const SORT_DIRECTION = {
  UNSELECTED: 0,
  ASC: 1,
  DESC: 2,
};

export const SORT_MAP = {
  1: '+',
  2: '-',
};
