import React from 'react';
import { useClasses } from 'common/utils/utils';
import styles from './RegularCard.style';
import { Card, CardContent, CardHeader, CardActions } from '@mui/material';

const RegularCard = (props) => {
  const { className, cardTitle, cardSubtitle, content, footer, ...other } = props;
  const classes = useClasses(styles);

  return (
    <Card className={className || classes.card} raised={true} elevation={7} {...other}>
      <CardHeader
        classes={{
          root: classes.cardHeader,
          title: classes.cardTitle,
          subheader: classes.cardSubtitle,
        }}
        title={cardTitle}
        subheader={cardSubtitle}
      />
      <CardContent>{content}</CardContent>
      {footer !== undefined ? (
        <CardActions className={classes.cardActions}>{footer}</CardActions>
      ) : null}
    </Card>
  );
};

export default RegularCard;
