export class FailedRequestException extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
    this.status = response && response.status;
  }
}

export const handleRequestException = (error) => {
  console.error(error);
  if (error.response) {
    if (error.response.data && error.response.data.error && error.response.data.error.message) {
      throw new FailedRequestException(error.response.data.error.message, error.response);
    } else if (error.response.data && error.response.data.errors) {
      const errors = error.response.data.errors;
      if (Array.isArray(errors)) {
        let sb = '';
        for (const value of errors) {
          sb += `${value}\n`;
        }
        throw new FailedRequestException(sb, error.response);
      } else {
        let sb = '';
        for (const [key, value] of Object.entries(errors)) {
          sb += `${key}: ${value}\n`;
        }
        throw new FailedRequestException(sb, error.response);
      }
    } else if (error.response.data && error.response.data.message) {
      throw new FailedRequestException(error.response.data.message, error.response);
    }
  }
  throw new FailedRequestException(error.message, error.response);
};
