import React from 'react';
import { useClasses } from 'common/utils/utils';

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexFlow: 'row wrap',
    width: '100%',
  },
};

const RegCardHeader = (props) => {
  const { titleNode, infoNode } = props;
  const classes = useClasses(styles);

  return (
    <div className={classes.wrapper}>
      {titleNode}
      {infoNode}
    </div>
  );
};

export default RegCardHeader;
