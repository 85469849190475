import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import selectors from 'features/authentication/store/selectors';
import { LoadingPineapple } from 'common/components';

const AuthenticatedRoute = ({ redirectPath = '/login', children }) => {
  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const isUpdating = useSelector(selectors.getIsUpdating);

  if (isUpdating) {
    return <LoadingPineapple shouldSpin={true} />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to='/login' />;
};

export default AuthenticatedRoute;
