import {
  createReducer,
  updatingStore,
  successfulUpdate,
  failedUpdate,
  successfulUpdateNonReload,
} from 'common/utils/utils';

const initialState = {
  isUpdating: false,
  id: '',
  first: '',
  last: '',
  email: '',
  roles: [],
  permissions: [],
  clinic: '',
  clinic_ref: '',
  tracking_enabled: true,
  error: '',
};

export const UserActions = {
  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  UPDATE_USER_SETTING_REQUEST: 'UPDATE_USER_SETTING_REQUEST',
  UPDATE_USER_SETTING_SUCCESS: 'UPDATE_USER_SETTING_SUCCESS',
  UPDATE_USER_SETTING_FAILURE: 'UPDATE_USER_SETTING_FAILURE',
  UPDATE_USER_SETTING_REQUEST_NON_RELOAD: 'UPDATE_USER_SETTING_REQUEST_NON_RELOAD',

  SIMULATE_USER: 'SIMULATE_USER',
  RESET_SIMULATED_USER: 'RESET_SIMULATED_USER',
  RESET_USER: 'RESET_USER',
};

const userReducer = createReducer(initialState, {
  [UserActions.FETCH_USER_REQUEST]: (state, actions) => updatingStore(state, actions),
  [UserActions.FETCH_USER_SUCCESS]: (state, actions) =>
    successfulUpdate(state, actions, [
      'id',
      'first',
      'last',
      'email',
      'cohort',
      'roles',
      'clinic',
      'clinic_ref',
      'tracking_enabled',
      'settings',
      'permissions',
      'cohort',
    ]),
  [UserActions.FETCH_USER_FAILURE]: (state, actions) => failedUpdate(state, actions),

  [UserActions.UPDATE_USER_REQUEST]: (state, actions) => updatingStore(state, actions),
  [UserActions.UPDATE_USER_SUCCESS]: (state, actions) => successfulUpdate(state, actions),
  [UserActions.UPDATE_USER_FAILURE]: (state, actions) => failedUpdate(state, actions),

  [UserActions.UPDATE_USER_SETTING_REQUEST]: (state, actions) => updatingStore(state, actions),
  [UserActions.UPDATE_USER_SETTING_SUCCESS]: (state, actions) => successfulUpdate(state, actions),
  [UserActions.UPDATE_USER_SETTING_FAILURE]: (state, actions) => failedUpdate(state, actions),

  [UserActions.UPDATE_USER_SETTING_REQUEST_NON_RELOAD]: (state, actions) =>
    successfulUpdateNonReload(state, actions),

  [UserActions.RESET_SIMULATED_USER]: (state, actions) => updatingStore(state, actions),
});

export default userReducer;
