/**
 * Used for Setting, getting, deleting and updating local storage
 * @author Dylan Sadnick
 * 01/13/2022
 * todo @dsadnick adding a enum or const object that holds they keys that are being used
 */
export class LocalStorageInterface {
  constructor(key, value) {
    this.key = key;
    this.value = value;
  }

  /**
   * a void method that will set the key:value in local storage
   */
  setKey() {
    try {
      window.localStorage.setItem(this.key, JSON.stringify(this.value));
    } catch (e) {
      //Cant set key:value in local Storage
    }
  }

  /**
   * will return the Parsed value from local storage of the key
   * @returns {any}
   */
  getKey() {
    try {
      let returnValue = window.localStorage.getItem(this.key);
      returnValue = JSON.parse(returnValue);
      return returnValue;
    } catch (e) {
      //Cant get key
    }
  }

  /**
   * a void method that will remove the key from storage
   */
  deleteKey() {
    try {
      window.localStorage.removeItem(this.key);
    } catch (e) {
      //Cant delete key
    }
  }
}
