import { createSelector } from 'reselect';
// TODO: remove unused code
// import managementSelectors from '../management/selectors';
// import applicationSelectors from 'features//application/store/selectors';
// import { PatientStates } from 'constants/states';
import { SORT_MAP } from 'common/constants/filters';

const getFiltersState = (state) => state.filters;

const getFilterDates = createSelector(getFiltersState, (filtersState) => ({
  dateStart: filtersState.dateStart,
  dateEnd: filtersState.dateEnd,
}));

const getFilterParams = createSelector(getFiltersState, (filtersState) => filtersState?.params);

const getSortOptions = createSelector(getFiltersState, (filtersState) => filtersState?.sortOptions);

const getRequiredParams = createSelector(
  getFiltersState,
  (filtersState) => filtersState?.requiredParams
);

const getQueryableFilters = createSelector(
  // getFilterDates,
  // getFilterParams,
  // getRequiredParams,
  getSortOptions,
  // applicationSelectors.getStatuses,
  // managementSelectors.getWorkflowSteps,
  (sortOptions) => {
    // Any requiredParams should override filterParams
    // const params = {
    //   ...filterParams,
    //   ...requiredParams,
    // };

    let order_by = [];
    for (const key in sortOptions) {
      if (sortOptions[key].sortDirection !== 0) {
        order_by.push(SORT_MAP[sortOptions[key].sortDirection] + key);
      }
    }
    const formatted = {
      // ctime__gte: filterDates.dateStart.toISOString(),
      // ctime__lte: filterDates.dateEnd.toISOString(),
      order_by: order_by,
    };

    // const nameMapping = {
    //   clinics: 'clinic_ref__in',
    //   computers: 'computer_ref__in',
    //   studyTypes: 'study__type__in',
    //   softwares: 'sw__in',
    //   currentAssignee: 'management__current_state__assignee__user__in',
    // };

    // Treat empty arrays as no filter at all
    // const formatQueryPart = (value) => {
    //   return !value.length ? undefined : value;
    // };
    // Object.entries(nameMapping).forEach(([k, v]) => (formatted[v] = formatQueryPart(params[k])));

    // if (formatted[nameMapping.currentAssignee]) {
    //   // If we are searching for patients with a null assignee, we need to clarify to only search assignable states
    //   // for such an assignee. Otherwise, the
    //   // unassignable states will also appear as results.
    //   formatted['management__current_state__assignable'] = true;
    // }

    // const assignee = formatQueryPart(params.assignee);
    // if (assignee) {
    //   // We have to do an elemMatch here in order to successfully search for "unassigned" studies - those where
    //   // an assignable state has a null user.
    //   // The rationale is that a step that is unassignable may have assignee.user === null, so we need to check
    //   // that the step is BOTH assignable and has the user assigned to it. This is not the same as using the filters
    //   // management__workflow__assignee__user__in && management__workflow__assignable since that may match
    //   // two different steps, one that has the specified user and another that is assignable.
    //   formatted['management__workflow__match'] = JSON.stringify({
    //     assignee__user__in: assignee,
    //     assignable: true,
    //   });
    // }

    // const stateValues = params.state;
    // if (stateValues.length) {
    //   const statusIn = [];
    //   const managementStateIn = [];
    //   // stateValues.forEach((state) => {
    //   //   if (Object.values(PatientStates).includes(state)) {
    //   //     statusIn.push(
    //   //       ...statuses
    //   //         .filter((statusObj) => statusObj.type === state)
    //   //         .map((statusObj) => statusObj.value)
    //   //     );
    //   //   } else {
    //   //     managementStateIn.push(state);
    //   //   }
    //   // });
    //   if (statusIn.length && managementStateIn.length) {
    //     formatted['__raw__'] = JSON.stringify({
    //       $or: [
    //         {
    //           status: {
    //             $in: statusIn,
    //           },
    //         },
    //         {
    //           'management.current_state.name': {
    //             $in: managementStateIn,
    //           },
    //         },
    //       ],
    //     });
    //   } else if (statusIn.length) {
    //     formatted['status__in'] = statusIn;
    //   } else if (managementStateIn.length) {
    //     formatted['management__current_state__name__in'] = managementStateIn;
    //   }
    // }

    return formatted;
  }
);

const getSearchInput = createSelector(getFiltersState, (filtersState) => filtersState?.searchInput);
const getSearchFields = createSelector(
  getFiltersState,
  (filtersState) => filtersState?.searchFields
);

export default {
  getFiltersState,
  getFilterDates,
  getFilterParams,
  getRequiredParams,
  getSortOptions,
  getQueryableFilters,
  getSearchInput,
  getSearchFields,
};
